import {useCallback, useEffect, useState} from 'react';
import {isSalesforceIntegrated} from '../../../api/exchangeContact';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';


const useIntegrationsController = () => {
    const user = useAppSelector(selectUser);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSalesforceEnabled, setIsSalesforceEnabled] = useState<boolean>(false);

    const isSalesforceIntegratedApi = useCallback((userId: number) => {
        setLoading(true);
        isSalesforceIntegrated(userId)
            .then(res => {
                setIsSalesforceEnabled(res.data ?? false);
            })
            .finally(() => setLoading(false));
    }, [user]);


    useEffect(() => {
        if (loading || !user) return;

        isSalesforceIntegratedApi(user.id);
    }, [user]);


    return {
        loading,
        isSalesforceEnabled
    };
};

export default useIntegrationsController;