import React, {FC, ReactNode, useMemo} from 'react';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning/warning.svg';
import i18n from '../../translations/i18n';
import {WarningModal} from '../common/warningModal/WarningModal';
import {isBlank, notBlank} from '../../utils/common';
import {useLocation, useNavigate} from 'react-router-dom';
import {navigateTo} from '../../constants/routes';
import {CardProfileActivateRequest} from '../../types/cardProfile';
import {activateCardProfile} from '../../api/cardProfile';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {useAlert} from '../../context/AlertProvider';
import {ERROR, SUCCESS} from '../../constants/common';

const ACTIVATE_QUERY_PARAM = 'activate';

interface ActivationModalProps {
    children: ReactNode;
}

const ActivationModal: FC<ActivationModalProps> = ({children}) => {
    const navigate = useNavigate();
    const {setAlert} = useAlert();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const user = useAppSelector(selectUser);

    const uuid = queryParams?.get(ACTIVATE_QUERY_PARAM);
    const activateExists = !!(uuid && notBlank(uuid));

    const onCancelActivation = () => navigate(`${navigateTo.analytics}`);

    const onConfirmActivation = () => {
        if (!uuid || isBlank(uuid)) return;

        const activateData: CardProfileActivateRequest = { userId: user.id };
        activateCardProfile(uuid, activateData)
            .then(res => {
                if (res.error) {
                    const errorMsg = res.status === 400 ? res.errorMsg : i18n.t('activate.failedToActivateProfile');
                    setAlert(ERROR, errorMsg);
                } else {
                    setAlert(SUCCESS, i18n.t('activate.yourProfileIsActivated'));
                }
            })
            .finally(() => navigate(`${navigateTo.myCards}`));
    };

    return (
        <>
            {children}
            <WarningModal
                isOpen={activateExists}
                icon={<WarningIcon/>}
                title={i18n.t('activate.profileActivation')}
                text={i18n.t('activate.assignProfile', {uuid} )}
                confirmBtnText={i18n.t('activate.yesActivate')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={onConfirmActivation}
                onCancel={onCancelActivation}
            />
        </>
    );
};

export default ActivationModal;