import {useMemo, useState} from 'react';
import {
    saveProfilCardInfo, updateCardProfileSelectAll, updateCompanyCardProfileSelectAll
} from '../../api/cardProfile';
import {
    Basic,
    CardProfileAbout,
    CardProfileSocialLinksUpdate,
    CardProfileStyleUpdate,
    ContactAndAdress
} from '../../types/cardProfile';
import {ERROR, SUCCESS} from '../../constants/common';
import {useAlert} from '../../context/AlertProvider';
import {COMPANY_ID} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import i18n from '../../translations/i18n';

const useSaveCardBulkController = (
    ids: number[], selectAll: boolean, isMyCards: boolean, noofCards: number
) => {
    const {setAlert} = useAlert();
    const user = useAppSelector(selectUser);

    const [loading, setLoading] = useState<boolean>(false);
    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const saveCardProfileInfoApi = (formData: FormData, searchText?: string, searchStatus?: string) => {
        if (isMyCards && selectAll) return updateCardProfileSelectAll(ids, formData, searchText, searchStatus);
        if (!isMyCards && selectAll && companyId) return updateCompanyCardProfileSelectAll(ids, companyId, formData, searchText, searchStatus);
        return saveProfilCardInfo(ids, formData);
    };

    const saveProfileInfo = async (
        aboutData?: CardProfileAbout, basic?: Basic,
        profileImage?: File | null, coverImage?: File | null,
        socialLinks?: CardProfileSocialLinksUpdate[], contact?: ContactAndAdress, style?: CardProfileStyleUpdate,
        searchText?: string, searchStatus?: string
    ) => {
        setLoading(true);
        const formData = new FormData();

        formData.append('basic', JSON.stringify(basic));
        formData.append('contact', JSON.stringify(contact));
        formData.append('style', JSON.stringify(style));

        if (aboutData) {
            formData.append('about', JSON.stringify(aboutData));
        }

        if (profileImage) {
            formData.append('profileImage', profileImage);
        }

        if (coverImage) {
            formData.append('coverImage', coverImage);
        }

        if (socialLinks?.length !== 0) {
            formData.append('socialLinks', JSON.stringify(socialLinks));
        }

        saveCardProfileInfoApi(formData, searchText, searchStatus)
            .then((res) => {
                if (res.error) {
                    setAlert(ERROR, res.error.response.data.error);
                } else {
                    setAlert(SUCCESS, i18n.t('messages.profilesSuccessfullyUpdated', {noofCards: noofCards}));
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        saveProfileInfo,
        cardProfileLoading: loading
    };
};

export default useSaveCardBulkController;
