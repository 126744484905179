import {
    createContext,
    type ReactNode,
    useCallback,
    useMemo,
    useState,
    useContext,
} from 'react';
  
import { ConfirmContext, ConfirmOptions, Nullable } from '../../types/confirm';
import { WarningModal } from '../../components/common/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning/warning.svg';
import i18n from '../../translations/i18n';

export const ConfirmCtx = createContext<Nullable<ConfirmContext>>(null);
  
interface Props {
    children: ReactNode;
}
  
export function ConfirmProvider({ children }: Props) {
    const [confirm, setConfirm] = useState<Nullable<ConfirmOptions>>(null);
  
    const [open, setOpen] = useState<boolean>(false);
  
    const show = useCallback(
        (confirmOptions: Nullable<ConfirmOptions>) => {
            setConfirm(confirmOptions);
            setOpen(true);
        },
        [setOpen]
    );
  
    const onConfirm = () => {
        confirm?.onConfirm?.();
        setOpen(false);
    };
  
    const onCancel = () => {
        confirm?.onCancel?.();
        setOpen(false);
    };
  
    const value = useMemo(() => ({ show }), [show]);
  
    return (
        <ConfirmCtx.Provider value={value}>
            <WarningModal 
                isOpen={open} 
                icon={<WarningIcon />}
                title={i18n.t('common.unsavedChanges')} 
                text={i18n.t('common.unsavedChangesText')} 
                confirmBtnText={i18n.t('common.leave')} 
                cancelBtnText={i18n.t('common.stay')} 
                onConfirm={onConfirm} 
                onCancel={onCancel}/>
            {children}
        </ConfirmCtx.Provider>
    );
}

export const useConfirm = () => {
    const context = useContext(ConfirmCtx);

    if (!context) {
        throw new Error('useConfirm must be used within a ConfirmProvider');
    }

    return context;
};