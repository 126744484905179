import {useCallback, useEffect, useState} from 'react';
import {getCompanyMembers} from '../../../api/company';
import {CompanyMemberPagination, CompanyMembersApiFilter} from '../../../types/company';
import {CompanyConverter} from '../../../converters/api/CompanyConverter';
import {useAlert} from '../../../context/AlertProvider';
import i18n from '../../../translations/i18n';
import {ERROR} from '../../../constants/common';
import {COMPANY_ID, IS_COMPANY_ADMIN} from '../../../store/utils/userSession';


export const defaultCompanyMembersApiFilter: CompanyMembersApiFilter = {
    page: 0,
    size: 6
};

export const useCompanyMemberController = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apiFilter, setApiFilter] = useState<CompanyMembersApiFilter>(defaultCompanyMembersApiFilter);
    const [companyMembersPagination, setCompanyMembersPagination] = useState<CompanyMemberPagination | undefined>(undefined);
    const {setAlert} = useAlert();

    const companyId = COMPANY_ID() as number;
    const isCompanyAdmin = IS_COMPANY_ADMIN();

    const getCompanyMembersApi = useCallback(() => {
        setLoading(true);
        getCompanyMembers(companyId, apiFilter)
            .then(res => {
                if (res?.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                    return;
                }

                const companyMemberData = CompanyConverter.convertFromCompanyMembersApiToCompanyMembersPagination(res.data);
                companyMemberData && setCompanyMembersPagination(companyMemberData);
            })
            .finally(() => setLoading(false));
    }, [companyId, apiFilter]);

    useEffect(() => {
        if (!companyId || loading || !isCompanyAdmin) return;

        getCompanyMembersApi();
    }, [companyId, apiFilter]);


    return {
        loading,
        apiFilter,
        setApiFilter,
        companyMembersPagination,
        companyId,
        getCompanyMembersApi
    };
};