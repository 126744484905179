import React, {FC, useEffect, useMemo, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import i18n from '../../translations/i18n';
import styles from './settings.module.scss';
import {NavigationTabs, Tab} from '../../components/common/navigationTabs/NavigationTabs';
import YourAccount from '../../components/settings/yourAccount/YourAccount';
import { FormProvider } from '../../context/profile/FormProvider';
import DataAndPrivacy from '../../components/settings/dataAndPrivacy/DataAndPrivacy';
import {Selector} from '../../components/common/selector/Selector';
import SupportTab from '../../components/settings/supportTab/SupportTab';
import CompanyMembers from '../../components/settings/companyMembers/CompanyMembers';
import {IS_COMPANY_ADMIN, IS_COMPANY_OWNER} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import CompanyRoles from '../../components/settings/companyRoles/CompanyRoles';
import Integrations from '../../components/settings/integrations/Integrations';
import {useLocation} from 'react-router-dom';

const YOUR_ACCOUNT = 'yourAccount';
const DATA_AND_PRIVACY = 'dataAndPrivacy';
const SUPPORT = 'supportTab';
const COMPANY_MEMBERS = 'companyMembers';
const COMPANY_ROLES = 'companyRoles';
const INTEGRATIONS = 'integrations';

type TabID = typeof YOUR_ACCOUNT | typeof DATA_AND_PRIVACY | typeof SUPPORT |
    typeof COMPANY_MEMBERS | typeof COMPANY_ROLES | typeof INTEGRATIONS;


const Settings: FC = () => {
    const [activeTab, setActiveTab] = useState<TabID>(YOUR_ACCOUNT);
    const [isLargeSizeScreen, setIsLargeSizeScreen] = useState(false);
    const user = useAppSelector(selectUser);

    const location = useLocation();
    const { salesforceForm } = location.state || {};

    const yourAccountLabel = i18n.t('common.yourAccount');
    const dataAndPrivacyLabel = i18n.t('common.dataAndPrivacy');
    const companyMembersLabel = i18n.t('common.companyMembers');
    const companyRolesLabel = i18n.t('common.roles');
    const integrationsTabLabel = i18n.t('common.integrations');
    const supportTabLabel = i18n.t('common.support');

    const tabs = useMemo(() => {
        const tabList: Tab[] = [
            {key: 1, value: yourAccountLabel, handleAction: () => setActiveTab(YOUR_ACCOUNT)},
            {key: 4, value: dataAndPrivacyLabel, handleAction: () => setActiveTab(DATA_AND_PRIVACY)},
            {key: 5, value: integrationsTabLabel, handleAction: () => setActiveTab(INTEGRATIONS)},
            {key: 6, value: supportTabLabel, handleAction: () => setActiveTab(SUPPORT)}
        ];

        const companyMemberTab: Tab = {key: 2, value: companyMembersLabel, handleAction: () => setActiveTab(COMPANY_MEMBERS)};
        IS_COMPANY_ADMIN() && tabList.splice(1, 0, companyMemberTab);

        const companyRolesTab: Tab = {key: 3, value: companyRolesLabel, handleAction: () => setActiveTab(COMPANY_ROLES)};
        IS_COMPANY_OWNER() && tabList.splice(2, 0, companyRolesTab);

        return tabList;
    }, [user.activeCompany]);

    const settingItems = useMemo(() => {
        const items: { value: string, name: string }[] = [
            {value: yourAccountLabel, name: yourAccountLabel},
            {value: dataAndPrivacyLabel, name: dataAndPrivacyLabel},
            {value: integrationsTabLabel, name: integrationsTabLabel},
            {value: supportTabLabel, name: supportTabLabel}
        ];

        const companyMemberItem = {value: companyMembersLabel, name: companyMembersLabel};
        IS_COMPANY_ADMIN() && items.splice(1, 0, companyMemberItem);

        const companyRolesItem = {value: companyRolesLabel, name: companyRolesLabel};
        IS_COMPANY_OWNER() && items.splice(2, 0, companyRolesItem);

        return items;
    }, [user.activeCompany]);

    const onSelect = (item: string) => {
        if (item == yourAccountLabel) setActiveTab(YOUR_ACCOUNT);
        else if (item == companyMembersLabel) setActiveTab(COMPANY_MEMBERS);
        else if (item == dataAndPrivacyLabel) setActiveTab(DATA_AND_PRIVACY);
        else if (item == integrationsTabLabel) setActiveTab(INTEGRATIONS);
        else if (item === supportTabLabel) setActiveTab(SUPPORT);
        else if (item === companyRolesLabel) setActiveTab(COMPANY_ROLES);
        else setActiveTab(YOUR_ACCOUNT);
    };

    const checkScreenSize = () => {
        setIsLargeSizeScreen(window.innerWidth < 992);
    };

    const getDefaultValue = () => (
        salesforceForm
            ? settingItems.find(s => s.value == integrationsTabLabel)?.name
            : settingItems[0].name
    );

    const getDefaultTab = () => (
        salesforceForm
            ? (tabs.find(t => t.value == integrationsTabLabel) ?? tabs[0])
            : tabs[0]
    );

    useEffect(() => {
        window.addEventListener('resize', checkScreenSize);
        checkScreenSize();
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        if (activeTab === COMPANY_MEMBERS && !IS_COMPANY_ADMIN()) {
            setActiveTab(YOUR_ACCOUNT);
        }
    }, [user.activeCompany]);

    useEffect(() => {
        salesforceForm && setActiveTab(INTEGRATIONS);
    }, [salesforceForm]);

    return (
        <FormProvider>
            <HomeHeader/>
            <div className={styles.container}>
                <div className={`${styles.title} ${styles.text}`}>
                    {i18n.t('header.accountSettings')}
                </div>
                <div className={styles.screen}>
                    <div className={styles.leftScreen}>
                        {isLargeSizeScreen
                            ? <Selector
                                items={settingItems}
                                defaultValue={getDefaultValue()}
                                onSelect={onSelect}
                                formStyle={styles.selector}
                            />
                            : <NavigationTabs
                                defaultTab={getDefaultTab()}
                                tabs={tabs}
                            />
                        }
                    </div>
                    <div className={styles.rightScreen}>
                        {activeTab === YOUR_ACCOUNT && <YourAccount/>}
                        {activeTab === DATA_AND_PRIVACY && <DataAndPrivacy/>}
                        {activeTab === SUPPORT && <SupportTab/>}
                        {activeTab === COMPANY_MEMBERS && <CompanyMembers/>}
                        {activeTab === COMPANY_ROLES && <CompanyRoles/>}
                        {activeTab === INTEGRATIONS && <Integrations salesforceFormActive={salesforceForm} />}
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default Settings;