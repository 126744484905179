import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {CompanyPermission} from '../../types/company';
import {COMPANY_ROLE_ADMIN, COMPANY_ROLE_OWNER, COMPANY_ROLE_USER} from '../../constants/accountRole';
import {getPermissionsByCompanyId} from '../../api/permissionController';
import {PermissionConverter} from '../../converters/api/PermissionConverter';

interface CompanySlice {
    companyPermission: CompanyPermission[];
}

const initialState: CompanySlice = {
    companyPermission: [
        { role: COMPANY_ROLE_OWNER, permissions: [] },
        { role: COMPANY_ROLE_ADMIN, permissions: [] },
        { role: COMPANY_ROLE_USER, permissions: [] },
    ]
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        updateCompanyPermission: (state, action) => {
            const permData = action.payload as CompanyPermission;

            if (permData) {
                state.companyPermission = state.companyPermission
                    .map(p => p.role === permData.role ? permData : p);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompanyPermission.fulfilled, (state, action) => {
            if (action?.payload) {
                state.companyPermission = action.payload;
            }
        });
    }
});

export const getCompanyPermission = createAsyncThunk<CompanyPermission[] | undefined, {
    companyId: number
}>('company/getCompanyPermission', async ({companyId}) => {
    const res = await getPermissionsByCompanyId(companyId);
    let permissions = undefined;

    if (!res.error) {
        permissions = PermissionConverter.convertFromPermissionApiToCompanyPermissions(res.data);
    }

    return permissions;
});

export default companySlice.reducer;

export const {
    updateCompanyPermission
} = companySlice.actions;

export const selectCompanyPermission = (state: any) => state.company.companyPermission;
