import {isEmpty} from '../../utils/common';
import {ExchangeContactData, ExchangeContactPagination, SalesforceCredentials} from '../../types/exchangeContacts';

export const ExchangeContactConverter = {
    convertFromExchangeContactApiToExchangeContactsPagination(data: any): ExchangeContactPagination | undefined {
        if (!data) return undefined;
        const content = data.content;

        return {
            contacts: ExchangeContactConverter.convertFromExchangeContactApiToExchangeContacts(content),
            noofElements: data.numberOfElements,
            size: data.size,
            totalElements: data.totalElements ?? 0,
            totalPages: data.totalPages
        };
    },
    convertFromExchangeContactApiToExchangeContacts(data: any[]): ExchangeContactData[] {
        if (isEmpty(data)) return [];

        return data
            .map(d => ExchangeContactConverter.convertFromExchangeContactApiToExchangeContact(d))
            .filter((exchangeContact): exchangeContact is ExchangeContactData => exchangeContact !== undefined);
    },
    convertFromExchangeContactApiToExchangeContact(data: any): ExchangeContactData | undefined {
        if (!data) return undefined;

        return {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
            company: data.company,
            website: data.website,
            notes: data.notes,
            profileImagePath: data.profileImagePath,
            created: data.created
        };
    },
    convertFromSalesforceApiToSalesforceCredentials(data: any): SalesforceCredentials | undefined {
        if (!data) return undefined;

        return {
            consumerKey: data.consumerKey,
            instanceUrl: data.instanceUrl,
            updated: data.updated
        };
    }
};