import {ExchangeContactsApiFilter} from '../../types/exchangeContacts';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import { ReactComponent as AvatarIcon } from '../../assets/icons/avatar.svg';
import { ReactComponent as NotesIcon } from '../../assets/icons/notes.svg';
import styles from './contacts.module.scss';
import {getFormattedTime} from '../../utils/date';
import {TextHighlighter} from '../../components/common/textHighlighter/TextHighlighter';
import React from 'react';
import {isEmpty} from '../../utils/common';

export const defaultExchangeContactsApiFilter: ExchangeContactsApiFilter = {
    page: 0,
    size: 8
};


export const getExchangeContactColumns = (
    openNotesRow: number | undefined,
    setPosition: (arg: any) => void,
    setOpenNotesRow: (rowId: { id: number, text: string } | undefined) => void,
    searchBy: string
): GridColDef[] => [
    {
        field: 'email',
        headerName: 'Name',
        type: 'string',
        minWidth: 260,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '16px' }}>
                    <div style={{height: '48px', width: '48px', borderRadius: '48px'}}>
                        <AvatarIcon style={{height: '48px', width: '48px', borderRadius: '48px'}}/>
                    </div>
                    <div className={styles.gridNameWrapper}>
                        <div className={styles.gridEmail}>
                            <TextHighlighter
                                text={params.row.email ?? ''}
                                textToHighlight={searchBy}
                            />
                        </div>
                        <div className={styles.gridName}>
                            <TextHighlighter
                                text={(params.row.firstName + ' ' + params.row.lastName) ?? ''}
                                textToHighlight={searchBy}
                            />
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        field: 'company',
        headerName: 'Company',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'phone',
        headerName: 'Phone',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'website',
        headerName: 'Website',
        type: 'string',
        minWidth: 120,
        flex: 1
    },
    {
        field: 'created',
        headerName: 'Date',
        type: 'string',
        minWidth: 170,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <div>
                    {getFormattedTime(params.row.created)}
                </div>
            );
        },
    },
    {
        field: 'notes',
        headerName: '',
        type: 'string',
        width: 80,
        renderCell: (params: GridRenderCellParams) => {
            const handleIconClick = (event: React.MouseEvent) => {
                const iconRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
                const scrollY = window.scrollY;
                const scrollX = window.scrollX;

                setPosition({
                    top: iconRect.bottom + scrollY + 8,
                    left: iconRect.left + scrollX - 300,
                });
                setOpenNotesRow(openNotesRow === params.row.id ? undefined : {id: params.row.id, text: params.row.notes} );
            };

            if (isEmpty(params.row.notes)) return <></>;

            return (
                <div
                    style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                    onClick={handleIconClick}
                >
                    <NotesIcon/>
                </div>
            );
        },
    }

];


