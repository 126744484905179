import React, {FC} from 'react';
import styles from './title.module.scss';

interface TitleProps {
    text: string;
    totalElements?: number;
}

const Title: FC<TitleProps> = ({
    text, totalElements
}) => {
    const hasAnyElement = totalElements ? totalElements > 0 : false;

    return (
        <div className={styles.titleWrapper}>
            <div className={styles.text}>
                {text}
            </div>
            {hasAnyElement &&
                <div className={styles.titleCount}>
                    ({totalElements})
                </div>
            }
        </div>
    );
};

export default Title;