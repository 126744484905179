import store from '../store';
import {BUSINESS, COMPANY_ROLE_ADMIN, COMPANY_ROLE_OWNER} from '../../constants/accountRole';

export const IS_BUSINESS_ACCOUNT = () => store.getState().userSession.user?.accountType === BUSINESS;

export const COMPANY_ID = () => store.getState().userSession.user?.activeCompany?.id;

export const IS_COMPANY_OWNER = () => store.getState().userSession.user?.activeCompany?.role === COMPANY_ROLE_OWNER;

export const USER_ID = () => store.getState().userSession.user?.id;

export const IS_COMPANY_ADMIN = () => {
    if (!IS_BUSINESS_ACCOUNT()) return false;

    const role = store.getState().userSession.user?.activeCompany?.role;
    return COMPANY_ROLE_OWNER === role || COMPANY_ROLE_ADMIN === role;
};

export const USER_COMPANY_ROLE = () => store.getState().userSession.user?.activeCompany?.role;