import {configDefaultWithToken, configForImages, getApiCall, postApiCall} from './base';
import {ExchangeContactsApiFilter, SalesforceCredentialsCreate} from '../types/exchangeContacts';


const exchangeContactUrl = '/api/exchange-contact';
const exchangeContactByCardUuidUrl = (uuid: string) => `${exchangeContactUrl}/card/${uuid}`;
const exchangeContactByUserIdUrl = (userId: number) => `${exchangeContactUrl}/user/${userId}`;
const exchangeContactExportUserIdUrl = (userId: number) => `${exchangeContactUrl}/user/${userId}/export`;
const salesforceCredentialsUrl = (userId: number) => `${exchangeContactUrl}/salesforce-credentials/user/${userId}`;
const salesforceCredentialsEnabledUrl = (userId: number) => `${exchangeContactUrl}/salesforce-credentials/user/${userId}/enabled`;

export const saveExchangeContactFromPreviewProfile = async (uuid: string, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await postApiCall(exchangeContactByCardUuidUrl(uuid), data, config);
};

export const saveExchangeContactFromContactsSection = async (userId: number, data: FormData) => {
    const config = {
        ...(await configForImages())
    };

    return await postApiCall(exchangeContactByUserIdUrl(userId), data, config);
};

export const getExchangeContacts = async (userId: number, filter: ExchangeContactsApiFilter) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            searchBy: filter.searchBy,
            page: filter.page,
            size: filter.size
        }
    };

    return await getApiCall(exchangeContactByUserIdUrl(userId), config);
};

export const exportExchangeContactsToCsv = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
        responseType: 'blob'
    };

    return await getApiCall(exchangeContactExportUserIdUrl(userId), config);
};

export const getSalesforceCredentials = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(salesforceCredentialsUrl(userId), config);
};

export const isSalesforceIntegrated = async (userId: number) => {
    const config = {
        ...(await configDefaultWithToken()),
    };

    return await getApiCall(salesforceCredentialsEnabledUrl(userId), config);
};

export const saveSalesforceCredentials = async (userId: number, secretHidden: boolean, data: SalesforceCredentialsCreate) => {
    const config = {
        ...(await configDefaultWithToken()),
        params: {
            secretHidden: secretHidden
        }
    };

    return await postApiCall(salesforceCredentialsUrl(userId), data, config);
};