import React, {FC, useMemo, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import { ThemeProvider } from '../../context/theme/ThemeProvider';
import MyCardsHeader from '../../components/cards/myCardsHeader/MyCardsHeader';
import styles from './my-cards.module.scss';
import {
    CardProfileFilterStatus,
    DeleteModal,
    UserCardProfile,
    UserCardProfileFilter, UserCardProfilesPagination
} from '../../types/userCardProfile';
import {navigateTo} from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_DEBOUNCE_TIME_IN_MS, GRID} from '../../constants/common';
import i18n from '../../translations/i18n';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning/warning.svg';
import {WarningModal} from '../../components/common/warningModal/WarningModal';
import { Pagination } from '@mui/material';
import EmptyCard from '../../components/EmptyCard/EmptyCard';
import {assignCardToCompany, removeCardFromCompany} from '../../api/company';
import {COMPANY_ID} from '../../store/utils/userSession';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import Divider from '../../components/common/divider/Divider';
import {ViewSelectorType} from '../../components/common/selector/ViewSelector';
import CardsGridView from './CardsGridView';
import CardsListView from './CardsListView';
import {FilerConverter} from '../../converters/api/FilterConverter';


const initialDeleteModal = {
    isOpen: false,
    cardProfileId: null,
};

export interface CardsProps {
    apiFilter: UserCardProfileFilter,
    setApiFilter: React.Dispatch<React.SetStateAction<UserCardProfileFilter>>,
    cardProfilesPagination: UserCardProfilesPagination | null,
    handleDeleteCardProfile: (cardProfileId: number) => Promise<void>,
    isMyCards?: boolean,
    reloadCardsApi?: () => void
}

const Cards: FC<CardsProps> = ({
    apiFilter, setApiFilter, cardProfilesPagination, handleDeleteCardProfile, isMyCards, reloadCardsApi
}) => {
    const navigate = useNavigate();
    const [deleteModal, setDeleteModal] = useState<DeleteModal>(initialDeleteModal);
    const [viewMode, setViewMode] = useState<ViewSelectorType>(GRID);
    const user = useAppSelector(selectUser);

    const noofCardProfilesTotal = cardProfilesPagination?.totalElements ?? 0;
    const cardProfiles: UserCardProfile[] = cardProfilesPagination?.profiles ?? [];
    const userHasNoCardProfile = noofCardProfilesTotal < 1;
    const totalPages = cardProfilesPagination?.totalPages;
    const hasAnyCardProfileFound = cardProfiles.length > 0;

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const onEdit = (cardProfileId: number) => navigate(`${navigateTo.profile}/${cardProfileId}`);
    const onPreview = (uuid: string) => {
        if (uuid) window.location.href = `${navigateTo.previewCardProfile}/${uuid}`;
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const onSearch = (text: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            searchByName: text
        }));
    };

    const onSelect = (option: CardProfileFilterStatus) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            status: option
        }));
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: page-1,
        }));
    };

    const handleOpenDeleteModal = (cardProfileId: number) => setDeleteModal({isOpen: true, cardProfileId});

    const handleCloseDeleteModal = () => setDeleteModal(initialDeleteModal);

    const handleOnConfirmDeleteModal = () => {
        if (!deleteModal.cardProfileId) return;
        handleDeleteCardProfile(deleteModal.cardProfileId)
            .finally(() => handleCloseDeleteModal());
    };

    const removeFromCompanyCards = async (uuid: string) => {
        companyId && removeCardFromCompany(uuid)
            .then(() => reloadCardsApi && reloadCardsApi());
    };

    const moveToCompanyCards = async (cardProfileId: number) => {
        companyId && assignCardToCompany(companyId, cardProfileId)
            .then(() => reloadCardsApi && reloadCardsApi());
    };

    const onViewSelect = (type: ViewSelectorType) => setViewMode(type);

    return (
        <>
            <HomeHeader/>
            <ThemeProvider>
                <div className={styles.container}>
                    <MyCardsHeader
                        onSearch={onSearchDebounced}
                        onSelect={onSelect}
                        onViewSelect={onViewSelect}
                        userHasCardProfile={cardProfilesPagination != null && !userHasNoCardProfile}
                        title={isMyCards ? i18n.t('header.personalCards') : i18n.t('header.companyCards')}
                        defaultViewMode={viewMode}
                        totalElements={noofCardProfilesTotal}
                    />
                    <div className={styles.dividerWrapper}><Divider/></div>
                    {userHasNoCardProfile &&
                        <div className={styles.emptyCard}>
                            <EmptyCard />
                        </div>
                    }
                    {hasAnyCardProfileFound
                        ? (viewMode === GRID
                            ? <CardsGridView
                                cardProfiles={cardProfiles}
                                onEdit={onEdit}
                                onDelete={handleOpenDeleteModal}
                                onPreview={onPreview}
                                searchText={apiFilter.searchByName ?? ''}
                                removeFromCompanyCards={removeFromCompanyCards}
                                moveToCompanyCards={moveToCompanyCards}
                                isMyCards={isMyCards}
                                reloadCardsApi={reloadCardsApi}
                            />
                            : <CardsListView
                                cardProfiles={cardProfiles}
                                onEdit={onEdit}
                                onDelete={handleOpenDeleteModal}
                                onPreview={onPreview}
                                searchText={apiFilter.searchByName ?? ''}
                                searchStatus={FilerConverter.convertFromCardProfileFilterStatusApiToCardProfileApiFilterStatus(apiFilter.status)}
                                removeFromCompanyCards={removeFromCompanyCards}
                                moveToCompanyCards={moveToCompanyCards}
                                isMyCards={isMyCards}
                                reloadCardsApi={reloadCardsApi}
                                noofCardProfilesTotal={noofCardProfilesTotal}
                            />)
                        : <div className={styles.noIcardsFound}>
                            {i18n.t('messages.noIcardsFound')}
                        </div>
                    }
                </div>
                {hasAnyCardProfileFound && totalPages && totalPages > 1 ? (
                    <div className={styles.pagination}>
                        <Pagination
                            count={totalPages}
                            shape="rounded"
                            onChange={handlePageChange}
                        />
                    </div>
                ): <></>}
                <WarningModal
                    isOpen={deleteModal.isOpen}
                    icon={<WarningIcon />}
                    title={i18n.t('modal.areYouSure')}
                    text={i18n.t('modal.deleteText')}
                    confirmBtnText={i18n.t('common.deleteCard')}
                    cancelBtnText={i18n.t('common.cancel')}
                    onConfirm={handleOnConfirmDeleteModal}
                    onCancel={handleCloseDeleteModal}
                />
            </ThemeProvider>
        </>
    );
};

export default Cards;