import {configDefaultWithToken, getApiCall, patchApiCall} from './base';
import {CompanyPermissionUpdater} from '../types/company';

const permissionUrl = '/api/permission';
export const permissionsByCompanyUrl = (companyId: number) => `${permissionUrl}/${companyId}`;


export const getPermissionsByCompanyId = async (companyId: number,) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await getApiCall(permissionsByCompanyUrl(companyId), config);
};

export const savePermissionsByCompanyId = async (companyId: number, data: CompanyPermissionUpdater) => {
    const config = {
        ...(await configDefaultWithToken())
    };

    return await patchApiCall(permissionsByCompanyUrl(companyId), data, config);
};