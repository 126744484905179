import {FC} from 'react';
import styles from './empty-card.module.scss';
import { ReactComponent as EmptyCardIcon } from '../../assets/icons/empty-card.svg';
import i18n from '../../translations/i18n';
import HeaderButton from '../common/button/HeaderButton';
import {openInNewTab} from '../../utils/common';
import {ICARDS_FULL_URL} from '../../constants/common';


interface EmptyCardProps {  
}

const EmptyCard: FC<EmptyCardProps> = () => {

    return (
        <div className={styles.container}>
            <EmptyCardIcon/>
            <div className={styles.title}>
                {i18n.t('myCards.youDontHaveCards')}
            </div>
            <div className={styles.description}>
                {i18n.t('myCards.clickBtnToCreateCard')}
            </div>
            <div>
                <HeaderButton
                    title={i18n.t('myCards.newCard')}
                    onClick={() => openInNewTab(ICARDS_FULL_URL)}
                />
            </div>
        </div>
    );
};

export default EmptyCard;