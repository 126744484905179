import React, {FC, useEffect, useState} from 'react';
import styles from './my-cards.module.scss';
import listViewStyles from '../../components/cards/cardBoxView/card-list-view.module.scss';
import CardBoxView from '../../components/cards/cardBoxView/CardBoxView';
import {
    CardProfileApiFilterStatus,
    UserCardProfile
} from '../../types/userCardProfile';
import {LIST} from '../../constants/common';
import {Checkbox} from '@mui/material';
import {CardListProvider} from '../../context/CardListProvider';
import i18n from '../../translations/i18n';
import CustomButtonWithIcon from '../../components/common/button/CustomButtonWithIcon';
import {ReactComponent as EditIcon} from '../../assets/icons/edit.svg';
import {useNavigate} from 'react-router-dom';
import {navigateTo} from '../../constants/routes';
import {encode} from '../../utils/jwt';


export interface SelectAllObject {
    selected: boolean;
    unselected: number[];
}

interface CardsListViewProps {
    cardProfiles: UserCardProfile[],
    onEdit: (cardProfileId: number) => void,
    onDelete: (cardProfileId: number) => void,
    onPreview: (uuid: string) => void,
    removeFromCompanyCards: (uuid: string) => Promise<void>,
    moveToCompanyCards: (cardProfileId: number) => Promise<void>,
    searchText: string,
    searchStatus: CardProfileApiFilterStatus,
    isMyCards?: boolean,
    reloadCardsApi?: () => void,
    noofCardProfilesTotal: number;
}

const CardsListView: FC<CardsListViewProps> = ({
    cardProfiles, onEdit, onDelete, onPreview, removeFromCompanyCards,
    moveToCompanyCards, searchText, searchStatus, isMyCards, reloadCardsApi, noofCardProfilesTotal
}) => {
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
    const [selectAll, setSelectAll] = useState<SelectAllObject>({selected: false, unselected: []});
    const [selected, setSelected] = useState<number[]>([]);

    const noofCards = selectAll.selected ? (noofCardProfilesTotal - selectAll.unselected.length) : selected.length;

    const addSelected = (newSelected: number) => {
        setSelected((prevSelected) =>
            prevSelected.includes(newSelected) ? prevSelected : [...prevSelected, newSelected]
        );

        setSelectAll((prevSelectAll) => {
            const unselectedSet = new Set(prevSelectAll.unselected);
            if (unselectedSet.has(newSelected)) {
                return {
                    ...prevSelectAll,
                    unselected: prevSelectAll.unselected.filter((item) => item !== newSelected)
                };
            } else {
                return { ...prevSelectAll };
            }
        });
    };

    const removeSelected = (toRemove: number) => {
        setSelectAll({
            selected: selectAll.selected,
            unselected: [...selectAll.unselected, toRemove]
        });

        setSelected((prevSelected) =>
            prevSelected.filter((item) => item !== toRemove)
        );
    };

    const toggleSelectAll = () => {
        if (selectAll.selected) {
            setSelected([]);
        }

        setSelectAll({selected: !selectAll.selected, unselected: []});
    };

    const handleOnBulkEdit = () => {
        if (selected.length === 1) {
            navigate(`${navigateTo.profile}/${selected[0]}`);
        } else if (selected.length > 1) {
            const selectAllProfiles = selectAll.selected;
            const token = encode({ ids: selectAllProfiles ? selectAll.unselected : selected });
            navigate(
                `${navigateTo.bulkEditProfiles}?selectAll=${selectAllProfiles}&len=${noofCards}&isMyCards=${isMyCards ?? false}&search=${searchText}&status=${searchStatus}&token=${token}`
            );
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.cardBox}>
            <div className={listViewStyles.headerRow}>
                <Checkbox
                    checked={selectAll.selected}
                    onChange={toggleSelectAll}
                    inputProps={{'aria-label': 'controlled'}}
                    style={{padding: 0}}
                />
                {!isSmallScreen && selected.length < 1 &&
                    <>
                        <div>ID</div>
                        <div style={{marginLeft: '100px'}}>{i18n.t('common.name')}</div>
                        <div style={{marginLeft: 'auto', marginRight: '90px'}}>{i18n.t('common.status')}</div>
                        <div>{i18n.t('common.actions')}</div>
                    </>
                }
                {noofCards > 0 &&
                    <>
                        <div>
                            {noofCards} {i18n.t('common.cardsSelected')}
                        </div>
                        <CustomButtonWithIcon
                            text={i18n.t('common.edit')}
                            onClick={handleOnBulkEdit}
                            icon={<EditIcon/>}
                            sx={`${listViewStyles.editBtn} ${listViewStyles.editBundleBtn}`}
                        />
                    </>
                }
            </div>
            {cardProfiles.map(profile => (
                <CardListProvider
                    key={profile.cardProfileId}
                    selectAll={selectAll}
                    addSelected={addSelected}
                    removeSelected={removeSelected}
                >
                    <CardBoxView
                        key={profile.cardProfileId}
                        profile={profile}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onPreview={onPreview}
                        searchText={searchText}
                        removeFromCompanyCards={removeFromCompanyCards}
                        moveToCompanyCards={moveToCompanyCards}
                        isMyCards={isMyCards}
                        reloadCardsApi={reloadCardsApi}
                        viewMode={LIST}
                    />
                </CardListProvider>
            )
            )}
        </div>
    );
};

export default CardsListView;