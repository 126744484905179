import React, {useMemo, useState} from 'react';
import HomeHeader from '../../components/layout/HomeHeader/HomeHeader';
import useContactsController from './useContactsController';
import {useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import styles from './contacts.module.scss';
import ContactsHeader from './contactsHeader/ContactsHeader';
import i18n from '../../translations/i18n';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_DEBOUNCE_TIME_IN_MS} from '../../constants/common';
import {ExchangeContactData} from '../../types/exchangeContacts';
import CustomDataGrid from '../../components/common/grid/CustomDataGrid';
import {getExchangeContactColumns} from './contactsColumnsUtils';
import SalesforcePopup from './salesforcePopup/SalesforcePopup';

const Contacts = () => {
    const user = useAppSelector(selectUser);
    const [openNotesRow, setOpenNotesRow] = useState<{ id: number, text: string } | undefined>(undefined);
    const [position, setPosition] = useState<{top: number; left: number} | undefined>(undefined);

    const {
        apiFilter, setApiFilter, exchangeContactPagination, reloadContactsApi
    } = useContactsController(user.id);

    const exchangeContacts: ExchangeContactData[] = exchangeContactPagination?.contacts ?? [];
    const totalPages = exchangeContactPagination?.totalPages ?? 1;
    const totalElements = exchangeContactPagination?.totalElements;

    const columns = useMemo(() => getExchangeContactColumns(
        openNotesRow?.id, setPosition, setOpenNotesRow, apiFilter.searchBy ?? ''
    ), [apiFilter, openNotesRow, apiFilter.searchBy]);

    const userHasContacts = (exchangeContactPagination && exchangeContactPagination.noofElements > 0) ?? false;

    const onSearch = (text: string) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: 0,
            searchBy: text
        }));
    };

    const onSearchDebounced = useDebouncedCallback((text: string) => {
        return onSearch(text);
    }, API_CALL_DEBOUNCE_TIME_IN_MS);

    const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setApiFilter(prevState => ({
            ...prevState,
            page: page - 1,
        }));
    };

    return (
        <>
            <HomeHeader/>
            <SalesforcePopup />
            <div className={styles.container}>
                <ContactsHeader
                    onSearch={onSearchDebounced}
                    title={i18n.t('common.contacts')}
                    userId={user.id}
                    reloadContactsApi={reloadContactsApi}
                    totalElements={totalElements}
                />
                {userHasContacts ?
                    <CustomDataGrid
                        rows={exchangeContacts}
                        columns={columns}
                        totalPages={totalPages}
                        handlePageChange={handlePageChange}
                    />
                    :
                    <div style={{alignSelf: 'center'}}>
                        {i18n.t('messages.noContactsFound')}
                    </div>
                }
                {openNotesRow?.id !== undefined && position &&
                    <div
                        style={{
                            position: 'absolute',
                            top: position.top,
                            left: position.left,
                            width: '300px',
                            background: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '8px',
                            boxShadow: '0px 4px 8px 0px #0000000F',
                            zIndex: 9999
                        }}
                    >
                        <div className={styles.notesTitle}>
                            {i18n.t('common.notes')}
                        </div>
                        <div className={styles.notesText}>
                            {openNotesRow.text}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

export default Contacts;