import React, {FC, RefObject, useEffect, useState} from 'react';
import styles from './card-list-view.module.scss';
import {Checkbox} from '@mui/material';
import {ACTIVE, INACTIVE, UserCardProfile} from '../../../types/userCardProfile';
import {ReactComponent as DefaultProfileIcon} from '../../../assets/icons/preview-avatar.svg';
import {ReactComponent as ThreeDotsIcon} from '../../../assets/icons/three-dots.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import i18n from '../../../translations/i18n';
import {TextHighlighter} from '../../common/textHighlighter/TextHighlighter';
import CustomButtonWithIcon from '../../common/button/CustomButtonWithIcon';
import {useCardListContext} from '../../../context/CardListProvider';


const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

interface CardListViewProps {
    profile: UserCardProfile,
    onEdit: (cardProfileId: number) => void,
    searchText: string,
    showMenu: boolean,
    handleModalView: () => void,
    menu: any,
    managedByOtherUserFlag: boolean,
    managedByCompanyFlag: boolean,
    menuIconRef: RefObject<HTMLDivElement>
}

const CardListView: FC<CardListViewProps> = ({
    profile, onEdit, searchText, showMenu, handleModalView, menu,
    managedByOtherUserFlag, managedByCompanyFlag, menuIconRef
}) => {
    const user = useAppSelector(selectUser);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const { selectAll, addSelected, removeSelected } = useCardListContext();
    const [checked, setChecked] = useState<boolean>(false);

    const isProfileActive = profile.status === ACTIVE;
    const isInactive = profile.status === INACTIVE;


    const profileIndicatorStyle = isProfileActive ? styles.active : isInactive ? styles.inactive : styles.notActivated;
    const profileIndicatorText = isProfileActive
        ? i18n.t('common.activated') : isInactive
            ? i18n.t('common.inactive') : i18n.t('common.notActivated');

    const profilePhotoPath = profile.basic.profilePhotoPath;
    const profilePhotoUrl = (imageBaseUrl && profilePhotoPath) ? (imageBaseUrl + profilePhotoPath) : undefined;

    const handleOnEdit = () => onEdit(profile.cardProfileId);

    const getProfilePhoto = () => (
        <div className={styles.imageWrapper}>
            <div className={styles.imageBox}>
                {profilePhotoUrl
                    ? <img src={profilePhotoUrl} alt='profile-photo' className={styles.image}/>
                    : <DefaultProfileIcon className={styles.image}/>
                }
            </div>
        </div>
    );

    const getProfileIndicator = () => (
        <div className={`${styles.profileIndicatorWrapper} ${styles.text} ${profileIndicatorStyle}`}>
            {profileIndicatorText}
        </div>
    );

    const handleCheck = () => {
        checked ? removeSelected(profile.cardProfileId) : addSelected(profile.cardProfileId);
        setChecked(prevState => !prevState);
    };

    useEffect(() => {
        const isSelectAll = selectAll.selected;
        if (isSelectAll !== checked) {
            const isUnselected = selectAll.unselected.includes(profile.cardProfileId);
            if ((!isUnselected && isSelectAll) || !isSelectAll) {
                setChecked(isSelectAll);
                checked ? removeSelected(profile.cardProfileId) : addSelected(profile.cardProfileId);
            }
        }
    }, [selectAll.selected]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.leftSection}>
                <Checkbox
                    checked={checked}
                    onChange={handleCheck}
                    inputProps={{'aria-label': 'controlled'}}
                    style={{padding: 0}}
                />
                {isSmallScreen && getProfilePhoto()}
                <div className={styles.userInfoSection}>
                    <div className={styles.textId}>
                        #{profile.cardProfileId}
                    </div>
                    {!isSmallScreen && getProfilePhoto()}
                    <div className={styles.fullnameBox}>
                        {managedByOtherUserFlag &&
                            <div className={styles.owner}>
                                Managed by {profile.owner.email}
                            </div>
                        }
                        {managedByCompanyFlag &&
                            <div className={styles.owner}>
                                Managed by {user.activeCompany?.name}
                            </div>
                        }
                        <div className={`${styles.text} ${styles.name}`}>
                            <TextHighlighter
                                text={profile.basic?.fullname ?? ''}
                                textToHighlight={searchText}
                            />
                        </div>
                        {isSmallScreen && getProfileIndicator()}
                    </div>
                </div>
            </div>
            <div className={styles.rightSection}>
                {!isSmallScreen && getProfileIndicator()}
                <CustomButtonWithIcon
                    text={i18n.t('common.edit')}
                    onClick={handleOnEdit}
                    icon={<EditIcon/>}
                    sx={styles.editBtn}
                />
                <div style={{position: 'relative'}}>
                    <div
                        className={`${styles.threeDots} ${showMenu && styles.menuActive}`}
                        onClick={handleModalView}
                        ref={menuIconRef}
                    >
                        <ThreeDotsIcon className={styles.moreIcon}/>
                    </div>
                    {showMenu && menu}
                </div>
            </div>
        </div>
    );
};

export default CardListView;