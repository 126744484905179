import {FC, useEffect, useMemo, useState} from 'react';
import styles from './home-header.module.scss';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import i18n from '../../../translations/i18n';
import { routes } from '../../../constants/routes';
import {IS_BUSINESS_ACCOUNT} from '../../../store/utils/userSession';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {Selector} from '../../common/selector/Selector';

const HomeHeader: FC = () => {
    const user = useAppSelector(selectUser);
    const isBusiness = useMemo(() => IS_BUSINESS_ACCOUNT(), [user.activeCompany]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const selectorItems = useMemo(() => {
        const items = [
            { value: 'analytics', name: i18n.t('header.analytics') },
            { value: 'personalCards', name: i18n.t('header.personalCards') },
            { value: 'contacts', name: i18n.t('common.contacts') },
            { value: 'settings', name: i18n.t('header.settings') },
        ];

        if (isBusiness) {
            items.splice(2, 0, { value: 'companyCards', name: i18n.t('header.companyCards') });
        }
        return items;
    }, [isBusiness, i18n.locale]);

    const defaultItem = useMemo(() => {
        if (location.pathname === routes.myCards) return 'personalCards';
        if (location.pathname === routes.companyCards) return 'companyCards';
        if (location.pathname === routes.contacts) return 'contacts';
        if (location.pathname === routes.settings) return 'settings';
        return 'analytics';
    }, [location.pathname, selectorItems, i18n.locale]);

    const handleSelect = (selectedItem: string) => {
        switch (selectedItem) {
            case 'personalCards': navigate(routes.myCards); break;
            case 'companyCards': navigate(routes.companyCards); break;
            case 'contacts': navigate(routes.contacts); break;
            case 'settings': navigate(routes.settings); break;
            default: navigate(routes.analytics);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 576);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (isSmallScreen) {
        return (
            <div className={styles.selectorContainer}>
                <Selector
                    defaultValue={defaultItem}
                    items={selectorItems}
                    onSelect={handleSelect}
                    formStyle={styles.selector}
                />
            </div>
        );
    }

    return (
        <>
            <nav className={styles.navContainer}>
                <div className={styles.navLinks}>
                    <NavLink to={routes.analytics} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.analytics')}
                    </NavLink>
                    <NavLink to={routes.myCards} className={({isActive})=> isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.personalCards')}
                    </NavLink>
                    {isBusiness &&
                        <NavLink to={routes.companyCards} className={({isActive}) => isActive ? styles.activeLink : styles.link}>
                            {i18n.t('header.companyCards')}
                        </NavLink>
                    }
                    <NavLink to={routes.contacts} className={({isActive}) => isActive ? styles.activeLink : styles.link}>
                        {i18n.t('common.contacts')}
                    </NavLink>
                    <NavLink to={routes.settings} className={({isActive}) => isActive ? styles.activeLink : styles.link}>
                        {i18n.t('header.settings')}
                    </NavLink>
                </div>
            </nav>
        </>
    );
};

export default HomeHeader;