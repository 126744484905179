import {useState} from 'react';
import {ERROR, SUCCESS} from '../../../constants/common';
import {useAlert} from '../../../context/AlertProvider';
import {
    saveExchangeContactFromContactsSection,
    saveExchangeContactFromPreviewProfile
} from '../../../api/exchangeContact';
import {ExchangeContactFormData} from './ExchangeContact';
import i18n from '../../../translations/i18n';

const useExchangeContactController = () => {
    const {setAlert} = useAlert();
    const [loading, setLoading] = useState<boolean>(false);

    const saveExchangeContactFromPreviewProfileApi = async (
        cardUuid: string, exchangeFormData: ExchangeContactFormData, onApiCallFinish: () => void
    ) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('contact', JSON.stringify(exchangeFormData));

        saveExchangeContactFromPreviewProfile(cardUuid, formData)
            .then((res) => {
                if (res.status === 400) {
                    setAlert(ERROR, res.error.response.data.error);
                } else if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.contactSuccessfullySaved'));
                    onApiCallFinish();
                }
            })
            .finally(() => setLoading(false));
    };

    const saveExchangeContactFromContactsSectionApi = async (
        userId: number, exchangeFormData: ExchangeContactFormData, onApiCallFinish: () => void
    ) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('contact', JSON.stringify(exchangeFormData));

        saveExchangeContactFromContactsSection(userId, formData)
            .then((res) => {
                if (res.status === 400) {
                    setAlert(ERROR, res.error.response.data.error);
                } else if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    setAlert(SUCCESS, i18n.t('messages.contactSuccessfullySaved'));
                    onApiCallFinish();
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        saveExchangeContactFromPreviewProfileApi,
        saveExchangeContactFromContactsSectionApi,
        loading
    };
};

export default useExchangeContactController;
