import React, {FC, useState} from 'react';
import styles from './your-account.module.scss';
import i18n from '../../../translations/i18n';
import CustomButton from '../../common/button/CustomButton';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {DISABLE_2FA, ENABLE_2FA} from '../../../constants/common';
import {setupUser2fa} from '../../../api/user';
import {WarningModal} from '../../common/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning.svg';


const SetupTwoFa: FC = () => {
    const user = useAppSelector(selectUser);

    const [openModal, setOpenModal] = useState<boolean>(false);

    const is2faEnabled = user.is2faEnabled;

    const handle2faAction = () =>  {
        setupUser2fa(user.id, is2faEnabled ? DISABLE_2FA : ENABLE_2FA)
            .then(() => location.reload());
    };

    return (
        <div className={styles.twoFactorAuth}>
            <div className={styles.twofaTextWrapper}>
                <div className={styles.twofaTitle}>
                    {i18n.t('settings.twofaTitle')}
                </div>
                <div className={styles.twofaDesc}>
                    {i18n.t('settings.twofaDesc')}
                </div>
                {is2faEnabled &&
                    <div className={styles.enabled}>
                        {i18n.t('common.enabled')}
                    </div>
                }
            </div>
            <CustomButton
                text={is2faEnabled ? i18n.t('settings.twofaDisableBtn') : i18n.t('settings.twofaEnableBtn')}
                onClick={() => setOpenModal(true)}
                sx={is2faEnabled ? styles.twofaDisableBtn : styles.twofaEnableBtn}
            />
            <WarningModal
                isOpen={openModal}
                icon={<WarningIcon />}
                title={is2faEnabled ? i18n.t('modal.disable2faTitle') : i18n.t('modal.setup2faTitle')}
                text={is2faEnabled ? i18n.t('modal.disable2faDesc') : i18n.t('modal.setup2faDesc')}
                confirmBtnText={i18n.t('common.proceed')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={handle2faAction}
                onCancel={() => setOpenModal(false)}
            />
        </div>
    );
};

export default SetupTwoFa;