import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import Header from '../layout/Header';
import styles from './contact.module.scss';
import { ReactComponent as MinusCircleIcon } from '../../../assets/icons/minusCircle.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';
import { Input } from '../../common/input/Input';
import i18n from '../../../translations/i18n';
import {Contacts, EMAIL, MOBILE_PHONE, OTHER_WEBSITE, WEBSITE} from '../../../types/contacts';
import {CardProfileContact, CardProfileAddress} from '../../../types/cardProfile';
import {
    canEditContact,
    checkIfAllowedToAddContact, getAvailableSelectorOptions,
    getMenuItemStyles,
    isWebsiteContactType,
    MAX_NOOF_WEBSITES
} from '../../../utils/contact/contact';
import { useToggleContext } from '../../../context/toggle/ToggleProvider';
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useCardProfileDataContext } from '../../../context/profile/CardProfileDataProvider';
import {
    getInputTypeAndPattern, NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX,
} from '../../../utils/validationUtils';
import {
    canEditCity,
    canEditCountry,
    canEditPostalCode,
    canEditStreetAddress
} from '../../../store/utils/company';
import {notBlank} from '../../../utils/common';

interface ContactProps {
    contacts: CardProfileContact[];
    address?: CardProfileAddress;
    showIcon?: boolean;
    saveToggleData: (data: any) => void;
    bulkView?: boolean;
    isCompanyCard: boolean;
}

const Contact: FC<ContactProps> = ({
    contacts, address, showIcon, saveToggleData, bulkView, isCompanyCard
}) => {
    const [isExtended, setIsExtended] = useState<boolean>(false);
    const {contactInfos, setContact, setAddress, setIsDirty} = useCardProfileDataContext();

    const [emptyFields, setEmptyFields] = useState<number[]>([]);

    const { profileToggle, setProfileToggle } = useToggleContext();

    useEffect(() => {
        if (!bulkView && contacts.length === 0) {
            setContact([
                { type: MOBILE_PHONE, displayType: MOBILE_PHONE, details: '' },
                { type: EMAIL, displayType: EMAIL, details: '' },
                { type: WEBSITE, displayType: WEBSITE, details: '' }
            ]);
        }
    }, [contacts]);

    const handleExtend = () => setIsExtended(prevIsExtended => !prevIsExtended);

    const handleToggle = () => {
        const newToggleState = {
            ...profileToggle,
            contact: !profileToggle?.contact};
        saveToggleData(newToggleState);
        setProfileToggle(newToggleState);
    };

    const handleAddContactInfo = () => {
        setContact([...contactInfos, { type: '', displayType: '', details: '' }]);
    };

    const isMaxNoofWebsitesSelected =  useMemo(
        () => contactInfos.filter(c => isWebsiteContactType(c.type))?.length >= MAX_NOOF_WEBSITES, [contactInfos]
    );
    const isMainWebsiteSelected = useMemo(
        () => contactInfos.some(c => isWebsiteContactType(c.displayType)), [contactInfos]
    );

    const availableSelectorOptions = getAvailableSelectorOptions(contactInfos, isMaxNoofWebsitesSelected, isMainWebsiteSelected);

    const handleOptionChange = (index: number, event: SelectChangeEvent) => {
        const targetValue = event.target.value;
        const selectedContact = targetValue === OTHER_WEBSITE ? WEBSITE : targetValue;
        const isPreviouslySelected = checkIfAllowedToAddContact(targetValue, contactInfos, isMaxNoofWebsitesSelected, isMainWebsiteSelected);

        if (!isPreviouslySelected) {
            setContact((prevContacts: CardProfileContact[]) => {
                const updatedContact = [...prevContacts];
                updatedContact[index].type = selectedContact;
                updatedContact[index].displayType = targetValue;
                return updatedContact;
            });
            setEmptyFields(prevEmptyFields => prevEmptyFields.filter(fieldIndex => fieldIndex !== index));
        }
        setIsDirty(true);
    };

    const handleRemoveLink = (index: number, contact: CardProfileContact) => {
        const isWebsiteType = isWebsiteContactType(contact.displayType);
        let updatedContact = contactInfos.filter((_, i) => i !== index);

        if (isWebsiteType) {
            let update = false;
            updatedContact = updatedContact.map(c => {
                if (!update && c.displayType === OTHER_WEBSITE) {
                    update = true;
                    return { ...c, displayType: WEBSITE };
                }

                return c;
            });
        }

        setContact(updatedContact);
        setIsDirty(true);
    };

    const handleContactInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const details = event.target.value;
        setContact((prevContacts: CardProfileContact[]) => {
            const updatedContacts = [...prevContacts];
            updatedContacts[index].details = details;
            return updatedContacts;
        });
        setEmptyFields(prevEmptyFields => prevEmptyFields.filter(fieldIndex => fieldIndex !== index));    
        setIsDirty(true);
    };

    const handleInputChange = (field: string, value: string) => {
        setAddress((prevData: CardProfileAddress) => ({ ...prevData, [field]: value }));
        setIsDirty(true);
    };

    return (
        <div className={styles.container}>
            <Header
                showIcon={showIcon}
                title={i18n.t('common.contact')}
                onExtend={handleExtend}
                isExtended={isExtended}
                isToggled={profileToggle?.contact} onToggle={handleToggle}
            />
            {isExtended && (
                <form className={styles.form}>
                    <div className={styles.inputsContainer}>
                        {contactInfos.map((contact, index) => {
                            const { type, pattern, placeholder } = getInputTypeAndPattern(contact.type as Contacts);
                            const isDefault = placeholder === i18n.t('common.pleaseSelectOption');
                            const selectorItems = notBlank(contact.displayType) ? [contact.displayType, ...availableSelectorOptions] : availableSelectorOptions;
                            const canEdit = !isCompanyCard || canEditContact(contact.type as Contacts);

                            return (
                                <div key={index} className={styles.inputContainer}>
                                    <FormControl sx={{ m: 1, minWidth: 140 }} size="small">
                                        <Select
                                            value={contact.displayType}
                                            onChange={(e) => handleOptionChange(index, e)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            error={emptyFields.includes(index)}
                                            style={{fontSize: '14px'}}
                                            disabled={!isDefault && !canEdit}
                                        >
                                            <MenuItem value="" disabled>
                                                {i18n.t('common.selectOption')}
                                            </MenuItem>
                                            {selectorItems.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={option}
                                                    style={getMenuItemStyles(option, contactInfos, isMaxNoofWebsitesSelected, isMainWebsiteSelected)}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth error={emptyFields.includes(index)}>
                                        <TextField
                                            required
                                            type={type}
                                            inputProps={{ pattern: pattern, maxLength: 64 }}
                                            value={contact.details}
                                            onChange={(e) => handleContactInputChange(index, e as ChangeEvent<HTMLInputElement>)}
                                            size="small"
                                            error={emptyFields.includes(index)}
                                            placeholder={placeholder}
                                            disabled={!canEdit}
                                        />
                                    </FormControl>
                                    {canEdit
                                        ? <MinusCircleIcon
                                            onClick={() => handleRemoveLink(index, contact)}
                                            className={styles.minus}
                                        />
                                        : <div className={styles.minus}></div>
                                    }
                                </div>
                            );
                        })}

                    </div>
                    {(availableSelectorOptions.length > 0) &&
                        <div className={styles.addLink} onClick={handleAddContactInfo}>
                            <PlusIcon />
                            <div className={styles.addText}>
                                {i18n.t('profile.contact.addField')}
                            </div>
                        </div>
                    }
                    <div className={styles.flexInput}>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.country')}
                            type="text"
                            value={address?.country || ''}
                            onChange={(e) => handleInputChange('country', e.target.value)}
                            max={32}
                            disabled={isCompanyCard && !canEditCountry()}
                        />
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.city')}
                            type="text"
                            value={address?.city || ''}
                            onChange={(e) => handleInputChange('city', e.target.value)}
                            max={32}
                            disabled={isCompanyCard && !canEditCity()}
                        />
                    </div>
                    <div className={styles.flexInput}>
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.streetAdress')}
                            type="text" value={address?.address || ''}
                            onChange={(e) => handleInputChange('address', e.target.value)}
                            max={64}
                            disabled={isCompanyCard && !canEditStreetAddress()}
                        />
                        <Input
                            notAllowedSpecialChars={NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX}
                            label={i18n.t('labels.zipPostal')}
                            type="text"
                            value={address?.zip || ''}
                            onChange={(e) => handleInputChange('zip', e.target.value)}
                            max={16}
                            disabled={isCompanyCard && !canEditPostalCode()}
                        />
                    </div>
                </form>
            )}
        </div>
    );
};

export default Contact;
