import {FC, useMemo} from 'react';
import styles from './my-cards-header.module.scss';
import i18n from '../../../translations/i18n';
import {SearchInput} from '../../common/searchInput/SearchInput';
import {Selector} from '../../common/selector/Selector';
import {CARD_PROFILE_FILTER_STATUSES, CardProfileFilterStatus} from '../../../types/userCardProfile';
import {ReactComponent as FilterIcon} from '../../../assets/icons/filter.svg';
import HeaderButton from '../../common/button/HeaderButton';
import ViewSelector, {ViewSelectorType} from '../../common/selector/ViewSelector';
import {openInNewTab} from '../../../utils/common';
import {ICARDS_FULL_URL} from '../../../constants/common';
import Title from '../../common/title/Title';

interface MyCardsHeaderProps {
    onSearch: (text: string) => void;
    onSelect: (option: CardProfileFilterStatus) => void;
    onViewSelect: (type: ViewSelectorType) => void;
    userHasCardProfile: boolean;
    title: string;
    defaultViewMode?: ViewSelectorType;
    totalElements?: number;
}

const MyCardsHeader: FC<MyCardsHeaderProps> = ({
    defaultViewMode, onSearch, onSelect, userHasCardProfile, title, onViewSelect, totalElements
}) => {
    const filterItems = useMemo(() => CARD_PROFILE_FILTER_STATUSES.map(fi => ({value: fi, name: fi})), []);

    return (
        <div className={styles.container}>
            <div className={styles.topSection}>
                <div className={styles.titleWrapper}>
                    <Title
                        text={title}
                        totalElements={totalElements}
                    />
                </div>
                <div className={styles.orderCard}>
                    <HeaderButton
                        title={i18n.t('myCards.newCard')}
                        onClick={() => openInNewTab(ICARDS_FULL_URL)}
                    />
                </div>
            </div>
            {userHasCardProfile &&
                <div className={styles.search}>
                    <SearchInput
                        placeholder={`${i18n.t('common.search')}...`}
                        onSearch={onSearch}
                        className={styles.input}
                    />
                    <div className={styles.filterAndView}>
                        <div className={styles.selectorWrapper}>
                            <Selector
                                label={i18n.t('labels.filterBy')}
                                items={filterItems}
                                defaultValue={CARD_PROFILE_FILTER_STATUSES[0]}
                                onSelect={onSelect as any}
                                icon={FilterIcon}
                                formStyle={styles.selector}
                            />
                        </div>
                        <div className={styles.selectorWrapper}>
                            <ViewSelector
                                defaultValue={defaultViewMode}
                                onSelect={onViewSelect}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default MyCardsHeader;