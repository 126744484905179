import React, {FC, useState} from 'react';
import i18n from '../../../translations/i18n';
import SettingsBox from '../SettingsBox';
import SettingsCard from '../SettingsCard';
import styles from './data-and-privacy.module.scss';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow/arrow-right.svg';
import {WarningModal} from '../../common/warningModal/WarningModal';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning.svg';
import {deleteUserAccount} from '../../../api/user';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import {useKeycloak} from '@react-keycloak/web';
import {CircularProgress} from '@mui/material';
import {openInNewTab} from '../../../utils/common';

const privacyPolicyURL = process.env.REACT_APP_PRIVACY_POLICY_URL;

const DataAndPrivacy: FC = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const user = useAppSelector(selectUser);
    const { keycloak } = useKeycloak();

    const handleOnConfirmDeleteModal = () => {
        setLoading(true);
        deleteUserAccount(user.id).then(() => keycloak.logout());
    };

    const handleCloseDeleteModal = () => setOpenModal(false);

    if (loading) return <CircularProgress />;

    return (
        <SettingsBox title={i18n.t('common.dataAndPrivacy')}>
            <SettingsCard
                label={i18n.t('settings.termsOfService')}
                onClick={() => openInNewTab(privacyPolicyURL)}
                actionElement={
                    <ArrowRightIcon />
                }
            />
            <SettingsCard
                label={i18n.t('settings.privacyPolicy')}
                onClick={() => openInNewTab(privacyPolicyURL)}
                actionElement={
                    <ArrowRightIcon />
                }
            />
            <SettingsCard
                label={i18n.t('settings.permanentlyDeleteYourAccount')}
                actionElement={
                    <div
                        className={`${styles.deleteBtn} ${styles.btnText}`}
                        onClick={() => setOpenModal(true)}
                    >
                        {i18n.t('settings.deleteYourAccount')}
                    </div>
                }
            />
            <WarningModal
                isOpen={openModal}
                icon={<WarningIcon />}
                title={i18n.t('settings.deleteYourAccount')}
                text={i18n.t('modal.deleteAccountText')}
                confirmBtnText={i18n.t('modal.deleteAccountConfirm')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={handleOnConfirmDeleteModal}
                onCancel={handleCloseDeleteModal}
                classNameBtn={styles.btnWrapper}
            />
        </SettingsBox>
    );
};

export default DataAndPrivacy;