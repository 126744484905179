import {styled} from '@mui/material';
import Switch from '@mui/material/Switch';


export const StyledCustomSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#FFFFFF',
            transform: 'translateX(22px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#242222',
            },
        }
    },
    '& .MuiSwitch-track': {
        height: 16
    }
}));