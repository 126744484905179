import store from '../store';
import {USER_COMPANY_ROLE} from './userSession';
import {
    EDIT_CITY, EDIT_COMPANY_DEPARTMENT,
    EDIT_COMPANY_LINKEDIN,
    EDIT_COMPANY_NAME,
    EDIT_COMPANY_POSITION, EDIT_COUNTRY,
    EDIT_COVER_IMAGE, EDIT_EMAIL,
    EDIT_FACEBOOK,
    EDIT_INSTAGRAM,
    EDIT_PERSONAL_LINKEDIN,
    EDIT_PHONE,
    EDIT_PINTEREST, EDIT_POSTAL_CODE, EDIT_PROFILE_ABOUT, EDIT_PROFILE_APPEARANCE,
    EDIT_PROFILE_FULLNAME,
    EDIT_PROFILE_IMAGE, EDIT_RECENT_ACTIVITIES, EDIT_REDDIT,
    EDIT_SNAPCHAT, EDIT_STREET_ADDRESS, EDIT_THREADS, EDIT_TIKTOK, EDIT_WEBSITE, EDIT_X,
    EDIT_YOUTUBE
} from '../../constants/permission';
import {CompanyPermissionType} from '../../types/company';

export const canEdit = (perm: CompanyPermissionType): boolean => {
    const companyStore = store.getState().company.companyPermission;
    const userCompany = companyStore.find(c => c.role === USER_COMPANY_ROLE());
    if (!userCompany) {
        return false;
    }

    return userCompany.permissions.some(permission =>
        permission.includes(perm)
    );
};

export const canEditFullname = (): boolean => canEdit(EDIT_PROFILE_FULLNAME);
export const canEditCompanyName = (): boolean => canEdit(EDIT_COMPANY_NAME);
export const canEditPosition = (): boolean => canEdit(EDIT_COMPANY_POSITION);
export const canEditDepartment = (): boolean => canEdit(EDIT_COMPANY_DEPARTMENT);
export const canEditProfileImage = (): boolean => canEdit(EDIT_PROFILE_IMAGE);
export const canEditCoverImage = (): boolean => canEdit(EDIT_COVER_IMAGE);
export const canEditPersonalLinkedin = (): boolean => canEdit(EDIT_PERSONAL_LINKEDIN);
export const canEditCompanyLinkedin = (): boolean => canEdit(EDIT_COMPANY_LINKEDIN);
export const canEditInstagram = (): boolean => canEdit(EDIT_INSTAGRAM);
export const canEditFacebook = (): boolean => canEdit(EDIT_FACEBOOK);
export const canEditYoutube = (): boolean => canEdit(EDIT_YOUTUBE);
export const canEditSnapchat = (): boolean => canEdit(EDIT_SNAPCHAT);
export const canEditPinterest = (): boolean => canEdit(EDIT_PINTEREST);
export const canEditX = (): boolean => canEdit(EDIT_X);
export const canEditTikTok = (): boolean => canEdit(EDIT_TIKTOK);
export const canEditThreads = (): boolean => canEdit(EDIT_THREADS);
export const canEditReddit = (): boolean => canEdit(EDIT_REDDIT);
export const canEditPhone = (): boolean => canEdit(EDIT_PHONE);
export const canEditEmail = (): boolean => canEdit(EDIT_EMAIL);
export const canEditWebsite = (): boolean => canEdit(EDIT_WEBSITE);
export const canEditCountry = (): boolean => canEdit(EDIT_COUNTRY);
export const canEditCity = (): boolean => canEdit(EDIT_CITY);
export const canEditStreetAddress = (): boolean => canEdit(EDIT_STREET_ADDRESS);
export const canEditPostalCode = (): boolean => canEdit(EDIT_POSTAL_CODE);
export const canEditAbout = (): boolean => canEdit(EDIT_PROFILE_ABOUT);
export const canEditAppearance = (): boolean => canEdit(EDIT_PROFILE_APPEARANCE);
export const canEditRecentActivities = (): boolean => canEdit(EDIT_RECENT_ACTIVITIES);
