import i18n from '../translations/i18n';
import {
    Contacts,
    EMAIL,
    WORK_PHONE,
    WEBSITE,
    MOBILE_PHONE,
    OFFICE_PHONE,
    HOME_PHONE,
    WORK_EMAIL, HOME_EMAIL
} from '../types/contacts';

interface FileValidationResult {
    isValid: boolean;
    error?: string;
}

export const fileValidation = (file: File): FileValidationResult => {
    const fileType = ['image/png', 'image/jpeg'].includes(file.type);
    const fileSize = file.size > 10 * 1024 * 1024;
    
    if (!fileType) {
        return { isValid: false, error: i18n.t('profile.error.invalidFileType') };
    }

    if (fileSize) {
        return { isValid: false, error: i18n.t('profile.error.fileTooLarge') };
    }

    return { isValid: true };
};


export const NOT_ALLOWED_SPECIAL_CHARS_FOR_NAME_REGEX: RegExp = /[!\\"#$%&()*+,\\/:;<=>?@[\\\]^_`{|}~]/g;
export const NOT_ALLOWED_SPECIAL_CHARS_REGEX: RegExp = /[!\\"#$%&'()*+,.\\/:;<=>?@[\\\]^_`{|}~]/g;
export const NOT_ALLOWED_MIN_SPECIAL_CHARS_REGEX: RegExp = /[<>;]/;


export const inputValidation = (value: string, notAllowedSpecialChars: RegExp): boolean => {
    return !notAllowedSpecialChars.test(value);
};

export const EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getInputTypeAndPattern = (value: Contacts) => {
    switch (value) {
        case WORK_PHONE:
        case MOBILE_PHONE:
        case OFFICE_PHONE:
        case HOME_PHONE:
            return { type: 'tel', pattern: '^(\\+|00)?[0-9]{5,}$', placeholder:'+010000000' };
        case EMAIL:
        case WORK_EMAIL:
        case HOME_EMAIL:
            return { type: 'email', pattern: EMAIL_REGEX_PATTERN, placeholder:'info@example.com' };
        case WEBSITE:
            return { type: 'text', pattern: /^(https?:\/\/|www\.)/i, placeholder:'www.example.com' };
        default:
            return { type: 'text', pattern: '', placeholder: i18n.t('common.pleaseSelectOption') };
    }
};