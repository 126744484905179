import React, {FC} from 'react';
import ModalContainer from '../modalContainer/ModalContainer';
import styles from './info-modal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning/warning.svg';
import { ReactComponent as SuccessInfoIcon } from '../../../assets/icons/success-info.svg';
import i18n from '../../../translations/i18n';

type ModalType = 'success' | 'error';

interface InfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    type: ModalType;
    title: string;
    text: string;
}

const InfoModal: FC<InfoModalProps> = ({
    isOpen, onClose, type, title, text
}) => {

    const getType = () => {
        if (type === 'success') return <SuccessInfoIcon />;
        if (type === 'error') return <WarningIcon />;
        return <></>;
    };

    if (!isOpen) return <></>;

    return (
        <ModalContainer>
            <div className={styles.header}>
                <div className={styles.icon} onClick={onClose}>
                    <CloseIcon stroke='#808080'/>
                </div>
                <div className={styles.typeIcon}>
                    {getType()}
                </div>
            </div>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.text}>
                {text}
            </div>
            <div
                className={styles.btn}
                onClick={onClose}
            >
                {i18n.t('common.ok')}
            </div>
        </ModalContainer>
    );
};

export default InfoModal;