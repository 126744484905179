import React, {FC, ReactNode} from 'react';
import styles from './settings.module.scss';
import Divider from '../common/divider/Divider';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow/arrow-right.svg';


interface SettingsBoxProps {
    title: string;
    actionElement?: ReactNode,
    children: ReactNode;
    goBack?: () => void;
    subtitleElement?: ReactNode
    sx?: string;
}

const SettingsBox: FC<SettingsBoxProps> = ({
    title, actionElement, children, goBack, subtitleElement, sx
}) => {

    const classNameContainer = sx ? `${styles.boxContainer} ${sx}` : styles.boxContainer;

    return (
        <div className={classNameContainer}>
            <div className={styles.titleContainer}>
                <div className={styles.titleWrapper}>
                    {goBack &&
                        <div
                            className={styles.backIcon}
                            onClick={goBack}
                        >
                            <ArrowIcon />
                        </div>
                    }
                    <div className={styles.title}>
                        {title}
                    </div>
                    {subtitleElement}
                </div>
                {actionElement}
            </div>
            <Divider />
            <>{children}</>
        </div>
    );
};

export default SettingsBox;