import {useCallback, useEffect, useState} from 'react';
import {isSalesforceIntegrated} from '../../../api/exchangeContact';


const useSalesforcePopupController = (userId: number) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showSalesforcePopup, setShowSalesforcePopup] = useState<boolean>(false);

    const isSalesforceIntegratedApi = useCallback((userId: number) => {
        setLoading(true);
        isSalesforceIntegrated(userId)
            .then(res => {
                setShowSalesforcePopup(!res.data ?? false);
            })
            .finally(() => setLoading(false));
    }, [userId]);


    useEffect(() => {
        if (loading) return;

        isSalesforceIntegratedApi(userId);
    }, [userId]);


    return {
        showSalesforcePopup,
        setShowSalesforcePopup
    };
};

export default useSalesforcePopupController;