export const getGridStyle = {
    borderRadius: '16px',
    paddingLeft: '12px',
    paddingRight: '12px',
    backgroundColor: '#FFFFFF',
    '--DataGrid-rowBorderColor': 'none',

    '& .MuiDataGrid-scrollbar--vertical': {
        display: 'none'
    },
    '& .MuiDataGrid-filler': {
        display: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none'
    },
    '& .MuiDataGrid-cell:focus': {
        outline: 'none'
    },
    '& .MuiDataGrid-main': {
        borderRadius: '16px'
    },
    '& .MuiDataGrid-row': {
        borderBottom: 'none'
    },
    '& .MuiDataGrid-cell': {
        display: 'flex',
        height: '82px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#242222',
        backgroundColor: '#FFFFFF',
        border: 'none'
    }, // Style all cells
    '& .MuiDataGrid-columnHeaders': {
        fontFamily: 'Lato',
        color: '#6B7280',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '24px',
        border: 'none'
    }, // Header style
};