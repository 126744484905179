import React, {FC, useState} from 'react';
import SettingsBox from '../SettingsBox';
import i18n from '../../../translations/i18n';
import SettingsCard from '../SettingsCard';
import styles from './company-roles.module.scss';
import PermissionModal from '../../permissionModal/PermissionModal';
import {CompanyPermission, CompanyRoleType} from '../../../types/company';
import {COMPANY_ROLE_ADMIN, COMPANY_ROLE_OWNER, COMPANY_ROLE_USER} from '../../../constants/accountRole';
import usePermissionController from '../../permissionModal/usePermissionController';

interface PermissionModalObject {
    open: boolean;
    role: CompanyRoleType | undefined;
}

const CompanyRoles: FC = () => {
    const [openPermissionModal, setOpenPermissionModal] = useState<PermissionModalObject>({open: false, role: undefined});

    const {
        loading, companyPermissions, savePermissionsByCompanyApi
    } = usePermissionController();

    const handleOpenPermissionModal = (role: CompanyRoleType) => setOpenPermissionModal({open: true, role: role});
    const handleClosePermissionModal = () => setOpenPermissionModal({open: false, role: undefined});


    const getPermissionButton = (companyRole: CompanyRoleType) => (
        <div
            className={`${styles.btn} ${styles.btnText}`}
            onClick={() => handleOpenPermissionModal(companyRole)}
        >
            {i18n.t('common.editPermissions')}
        </div>
    );

    const getCompanyPermissions = (companyRole?: CompanyRoleType): CompanyPermission | undefined => (
        companyRole ? companyPermissions.find(cp => cp.role === companyRole) : undefined
    );

    return (
        <>
            <SettingsBox
                title={i18n.t('common.roles')}
                sx={styles.settingsBox}
            >
                <div className={styles.header}>
                    <div className={styles.role}>{i18n.t('common.role')}</div>
                    <div className={styles.title}>{i18n.t('common.permissions')}</div>
                </div>
                <SettingsCard
                    label={i18n.t('common.owner')}
                    actionElement={getPermissionButton(COMPANY_ROLE_OWNER)}
                    sx={styles.settings}
                />
                <SettingsCard
                    label={i18n.t('common.admin')}
                    actionElement={getPermissionButton(COMPANY_ROLE_ADMIN)}
                    sx={styles.settings}
                />
                <SettingsCard
                    label={i18n.t('common.user')}
                    actionElement={getPermissionButton(COMPANY_ROLE_USER)}
                    sx={styles.settings}
                />
            </SettingsBox>

            {openPermissionModal &&
                <PermissionModal
                    isOpen={openPermissionModal.open}
                    role={openPermissionModal.role}
                    onClose={handleClosePermissionModal}
                    permissions={getCompanyPermissions(openPermissionModal.role)}
                    onSave={savePermissionsByCompanyApi}
                    loading={loading}
                />
            }
        </>
    );
};

export default CompanyRoles;