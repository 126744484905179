import React, {ChangeEvent, FC, ReactElement, ReactNode, useState} from 'react';
import styles from './file-input.module.scss';
import { Input } from '../input/Input';
import { fileValidation } from '../../../utils/validationUtils';


interface FileInputProps {
    id: string;
    label: string;
    className?: string; 
    labelClassName?: string; 
    children?: ReactNode;
    onFileChange?: (file: File) => void;
    icon?: ReactElement;
    disabled?: boolean;
}

const FileInput: FC<FileInputProps> = ({
    id, label, labelClassName, children, className, onFileChange, icon, disabled
}) => {
    const [error, setError] = useState<string | null>(null);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (disabled) return;
        const file = e.target.files?.[0];
        if (!file) return;

        const validationResult = fileValidation(file);
        if (!validationResult.isValid) {
            setError(validationResult.error || '');
            return;
        }
        setError(null);
        onFileChange && onFileChange(file);
    };
  
    return (
        <label className={`${labelClassName} ${styles.label}`} htmlFor={id}>
            {icon && icon}
            {label}
            {children}
            <Input
                type="file"
                id={id}
                onChange={handleFileChange}
                className={className}
                disabled={disabled}
            />
            {error && <div className={styles.error}>{error}</div>}
        </label>
    );
};

export default FileInput;