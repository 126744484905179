import {FC} from 'react';
import styles from './profile-header.module.scss';
import { ReactComponent as LeftArrowIcon } from '../../../assets/icons/arrow/left-arrow.svg';
import { ReactComponent as AvatarPeopleIcon } from '../../../assets/icons/avatar-people.svg';
import {NavLink, useNavigate} from 'react-router-dom';
import i18n from '../../../translations/i18n';
import {navigateTo} from '../../../constants/routes';
import SaveButton from '../../common/button/SaveButton';
import {ALL} from '../../../types/userCardProfile';

interface BulkProfileHeaderProps {
    isDirty: boolean;
    saveInfos: () => void;
    loading: boolean;
    isSticky?: boolean;
    selectAll: boolean;
    isMyCards: boolean;
    noofCards: number;
    idsToken: string;
    searchText: string;
    searchStatus: string;
}


const BulkProfileHeader: FC<BulkProfileHeaderProps> = ({
    isDirty, saveInfos, loading, isSticky,
    selectAll, isMyCards, noofCards, idsToken, searchText, searchStatus
}) => {
    const navigate = useNavigate();

    const toMyCardsPage = () => navigate(`${navigateTo.myCards}`);

    return (
        <div className={`${styles.headerContainer} ${isSticky ? styles.sticky : ''}`}>
            <nav className={styles.navContainer}>
                <div className={styles.leftLinks}>
                    <a className={styles.return} onClick={toMyCardsPage}>
                        <div><LeftArrowIcon/></div>
                        <div className={styles.avatar}>
                            <AvatarPeopleIcon className={styles.avatarImage} />
                        </div>
                    </a>
                    <span className={`${styles.hideMobile} ${styles.navLinks}`}>
                        <NavLinks
                            selectAll={selectAll}
                            isMyCards={isMyCards}
                            noofCards={noofCards}
                            idsToken={idsToken}
                            searchText={searchText}
                            searchStatus={searchStatus ?? ALL}
                        />
                    </span>
                </div>
                <div className={styles.rightLinks}>
                    <SaveButton
                        isDirty={isDirty}
                        loading={loading}
                        saveInfos={saveInfos}
                        className={styles.hideMobile}
                    />
                </div>
            </nav>
            <nav className={styles.navMobileContainer}>
                <NavLinks
                    selectAll={selectAll}
                    isMyCards={isMyCards}
                    noofCards={noofCards}
                    idsToken={idsToken}
                    searchText={searchText}
                    searchStatus={searchStatus}
                />
            </nav>
        </div>
    );
};

interface NavLinksProps {
    selectAll: boolean;
    isMyCards: boolean;
    noofCards: number;
    idsToken: string;
    searchText: string;
    searchStatus: string;
}

const NavLinks: FC<NavLinksProps> = ({
    selectAll, isMyCards, noofCards, idsToken, searchText, searchStatus
}) => {
    const urlParamsPart = `selectAll=${selectAll}&len=${noofCards}&isMyCards=${isMyCards ?? false}&search=${searchText}&status=${searchStatus}&token=${idsToken}`;

    return (
        <>
            <NavLink
                to={`${navigateTo.bulkEditProfiles}?${urlParamsPart}`}
                className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
            >
                {i18n.t('header.profilePage')}
            </NavLink>
            <NavLink
                to={`${navigateTo.bulkEditWalletProfiles}?${urlParamsPart}`}
                className={({ isActive }) => (isActive ? styles.activeLink : styles.link)}
            >
                {i18n.t('header.walletCard')}
            </NavLink>
        </>
    );
};

export default BulkProfileHeader;