import React, {FC} from 'react';
import styles from './salesforce-popup.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';
import useSalesforcePopupController from './useSalesforcePopupController';
import {useNavigate} from 'react-router-dom';
import {routes} from '../../../constants/routes';


const SalesforcePopup: FC = () => {
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();

    const { showSalesforcePopup, setShowSalesforcePopup } = useSalesforcePopupController(user.id);

    const goToSalesforceIntegrationForm = () => {
        navigate(routes.settings, { state: { salesforceForm: true } });
    };

    if (!showSalesforcePopup) return <></>;

    return (
        <div className={styles.salesforcePopup}>
            <div className={styles.salesforceWrapper}>
                {i18n.t('salesforce.enableIntegration')}
                <div
                    className={styles.salesforceConnect}
                    onClick={goToSalesforceIntegrationForm}
                >
                    {i18n.t('common.connect')}&nbsp;→
                </div>
            </div>
            <CloseIcon
                stroke="#FFFFFF"
                style={{cursor: 'pointer'}}
                onClick={() => setShowSalesforcePopup(false)}
            />
        </div>
    );
};

export default SalesforcePopup;