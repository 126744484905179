export const GERMAN = {
    analytics: {
        country: 'Land',
        previousPeriod: 'vorheriger Zeitraum',
        users: 'Benutzer'
    },
    header: {
        accountSetting: 'Einstellungen',
        accountSettings: 'Einstellungen',
        analytics: 'Analyse',
        companyCards: 'Firmenkarten',
        personalCards: 'Persönliche Karten',
        profilePage: 'Profil',
        walletCard: 'Wallet Card',
        settings: 'Einstellungen',
        signedInAs: 'Eingeloggt als',
        signOut: 'Abmelden',
        switchToBusinessAccount: 'Wechseln Sie zum Geschäftskonto',
        visitWebsite: 'Besuchen Sie Website',
        youAreEditing: 'Sie bearbeiten {{noofCards}} Karten'
    },
    common: {
        about: 'Über uns',
        accepted: 'Akzeptiert',
        actions: 'Aktionen',
        activated: 'Aktiviert',
        addMember: 'Mitglied hinzufügen',
        admin: 'Admin',
        admins: 'administratoren',
        appearance: 'Erscheinung',
        allCards: 'Alle Karten',
        assign: 'Zuordnen',
        assignCard: 'Karte zuweisen',
        assignCardTo: 'Karte zuweisen zu',
        authentication: 'Authentifizierung',
        cancel: 'Löschen',
        cardAssignedTo: 'Karteninhaber wechseln',
        cardsSelected: 'Karten ausgewählt',
        cardViews: 'Karten Ansichten',
        changeCardOwner: 'Ändern Sie den iCard-Besitzer',
        changePassword: 'Kennwort ändern',
        changesSaved: 'Änderungen gespeichert',
        close: 'Schließen',
        companyCards: 'Firmenkarten',
        companyLogo: 'Firmenlogo',
        companyMembers: 'Firmenmitglieder',
        confirmPassword: 'Passwort bestätigen',
        connect: 'Verbinden',
        connected: 'Verbunden',
        contact: 'Kontakt',
        contacts: 'Kontakte',
        contactSaved: 'Kontakt gespeichert',
        copy: 'Kopieren',
        copied: 'Kopiert!',
        currentPassword: 'Aktuelles Passwort',
        dataAndPrivacy: 'Daten und Privatsphäre',
        deleteCard: 'Karte löschen',
        deletePermanently: 'Endgültig löschen',
        discard: 'Verwerfen',
        dismiss: 'Zurückweisen',
        download: 'Herunterladen',
        downloadInstructions: 'Download-Anweisungen',
        edit: 'Bearbeiten',
        editCard: 'Karte bearbeiten',
        editPermissions: 'Berechtigungen bearbeiten',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'E-Mail-Adresse',
        enabled: 'Aktiviert',
        exchangeContact: 'Austauschkontakt',
        expired: 'Abgelaufen',
        export: 'Export',
        facebook: 'Facebook',
        general: 'Allgemein',
        grid: 'Netz',
        inactive: 'Inaktivieren',
        instagram: 'Instagram',
        integrations: 'Integrationen',
        inviteAs: 'Einladen als',
        inviteNewMember: 'Laden Sie ein neues Mitglied ein',
        language: 'Sprache',
        leave: 'Verlassen',
        links: 'Links',
        linkedin: 'Linkedin',
        linkedinCompany: 'Linkedin (Unternehmen)',
        linkedinPersonal: 'Linkedin (Persönlich)',
        managedBy: 'Verwaltet von',
        list: 'Liste',
        markAllAsRead: 'Alle als gelesen markieren',
        member: 'Mitglied',
        message: 'Nachricht',
        more: 'Mehr',
        moveToCompany: 'Nach Firmenkarten verschieben',
        moveToMyCards: 'Zu Persönliche Karten verschieben',
        personalCards: 'Persönliche Karten',
        name: 'Name',
        newContact: 'Neuer Kontakt',
        newPassword: 'Neues Passwort',
        noFileChosen: 'Keine Datei ausgewählt',
        notActivated: 'Nicht aktiviert',
        notes: 'Notizen',
        notifications: 'Benachrichtigungen',
        optional: 'optional',
        ok: 'Ok',
        or: 'oder',
        other: 'Andere',
        others: 'Andere',
        owner: 'Eigentümer',
        owners: 'eigentümer',
        password: 'Passwort',
        pending: 'Ausstehend',
        permissions: 'Berechtigungen',
        phone: 'Telefonnummer',
        pinterest: 'Pinterest',
        previewCard: 'Vorschau',
        proceed: 'Fortfahren',
        profilePicture: 'Profilbild',
        pleaseSelectOption : 'Please select an option',
        qrCode: 'QR code',
        recentActivities: 'Letzte Aktivitäten',
        reddit: 'Reddit',
        refreshPage : 'Bitte aktualisieren Sie die Seite, um fortzufahren',
        reload: 'Neu laden',
        remove: 'Entfernen',
        removeMember: 'Mitglied entfernen',
        role: 'Rolle',
        roles: 'Rollen',
        save: 'Speichern',
        saveChanges: 'Änderungen speichern',
        saveContact: 'Kontakt speichern',
        search: 'Suchen',
        selectOption: 'Option auswählen',
        selectPlatform: 'Plattform auswählen',
        send: 'Schicken',
        sendEmail: 'E-Mail senden',
        sent: 'Gesendet',
        sessionExpired: 'Sitzung abgelaufen',
        setInactive: 'Deaktivieren',
        share: 'Teilen',
        shareCard: 'Karte teilen',
        snapchat: 'Snapchat',
        stay: 'Bleiben',
        status: 'Status',
        subject: 'Objekt',
        subjectLine: 'Die Betreffzeile',
        submit: 'Einreichen',
        support: 'Unterstützung',
        takeAphoto: 'Machen Sie ein Foto',
        threads: 'Threads',
        today: 'Heute',
        tiktok: 'Tiktok',
        title: 'Titel',
        to: 'Zu',
        uniqueUsers: 'Einzelne Benutzer',
        unnamed: 'Unbenannt',
        unsavedChanges: 'Nicht gespeicherte Änderungen',
        unsavedChangesText: 'Es sieht so aus, als hätten Sie etwas bearbeitet. Wenn Sie die Seite vor dem Speichern verlassen, gehen Ihre Änderungen verloren.',
        user: 'Benutzer',
        users: 'benutzer',
        usersByCountries: 'Benutzer nach Ländern',
        view: 'Ansicht',
        viewAll: 'Alle anzeigen',
        viewMode: 'Ansichtsmodus',
        yourAccount: 'Deine Konto',
        website: 'Webseite',
        whatsapp: 'WhatsApp',
        x: 'X',
        yes: 'Ja',
        yourBusinessCard: 'Deine Visitenkarte',
        youtube: 'YouTube'
    },
    contact: {
        description1: 'Sie haben Fragen oder benötigen Beratung?',
        description2: 'Wir sind hier um zu helfen!',
        hello: 'Hallo!',
        liveChat: 'Live-Chat'
    },
    modal: {
        addUserRoleText: 'Sind Sie sicher, dass Sie {{username}} die {{role}} rolle zuweisen möchten?',
        areYouSure: 'Bist du sicher?',
        changeCardOwnerDesc: 'Wenn Sie den Karteninhaber ändern, können Sie dieses Kartenprofil nicht mehr verwalten oder anzeigen',
        deleteAccountConfirm: 'Ja, ich möchte meinen Account löschen',
        deleteAccountText: 'Bist du sicher, dass du deinen iCards Account löschen möchtest? Wenn der Account einmal gelöscht wurde, kann er nicht wiederhergestellt werden.',
        deleteText: 'Diese Aktion kann nicht rückgängig gemacht werden. Gelöschte Karten können nicht wiederhergestellt werden. Verbundene QR Codes und Links werden ungültig.',
        disable2faTitle: 'Zwei-Faktor-Authentifizierung deaktivieren',
        disable2faDesc: 'Möchten Sie die Zwei-Faktor-Authentifizierung deaktivieren?',
        enterCompanyName: 'Geben Sie den Firmennamen ein',
        hiThere: 'Hallo 👋',
        howCanIhelpYou: 'Wie kann ich dir helfen',
        iCardsTeam : 'iCards Team',
        iCardsTeamSubTitle : 'Normalerweise antwortet man innerhalb eines Tages',
        lostPhysicalCard: 'Karte verloren? ',
        lostPhysicalCardText: 'Für den Fall, dass deine Karte verlogen geht oder gestohlen wird, kannst du deine Daten schützen indem du sie deaktivierst. Falls die Karte beschädigt ist, kannst du eine Ersatzkarte bestellen. Die Daten auf der Karte sowie der Zugangslink bleiben bestehen.',
        orderNewCardText: 'Bestelle eine Ersatzkarte mit denselben Infos wie auf deiner ursprünglichen Karte. Auch der Zugangslink ändert sich nicht.',
        permissionModalText: 'Definieren Sie, was {{role}} im Unternehmen bearbeiten können.',
        removeCompanyInvitationTitle: 'Unternehmenseinladung entfernen',
        removeCompanyInvitationDesc: 'Die Unternehmenseinladung für {{userEmail}} wird entfernt.',
        removeCompanyMemberTitle: 'Unternehmensmitglied entfernen',
        removeCompanyMemberDesc: '{{name}} kann nur entfernt werden, wenn ihm keine Firmenkarte zugewiesen ist. Alle iCards, die {{name}} besitzt, werden ebenfalls aus dem Unternehmen entfernt.',
        setInactiveText : 'Hier kannst du den Link zu deinem Profil deaktivieren. Falls du eine Ersatzkarte mit einem neuem Profil benötigst, kannst du danach mit dem Bestellprozess starten.',
        startChat : 'Chat beginnen',
        setup2faTitle: 'Zwei-Faktor-Authentifizierung einrichten',
        setup2faDesc: 'Möchten Sie mit der Einrichtung der Zwei-Faktor-Authentifizierung fortfahren? Sie müssen die Einrichtung abschließen, bevor Sie sie wieder deaktivieren können.'
    },
    myCards : {
        clickBtnToCreateCard: 'Klicke auf den Button um deine erste digitale Visitenkarte zu erstellen',
        newCard: 'Neue Karte',
        orderNewCard: 'Neue Karte bestellen',
        setInactiveText: 'Deaktiviere diese Karte hier, falls sie verloren geht, gestohlen wird oder nicht mehr in Gebrauch ist. Mit dieser Aktion werden die Links zu deinem Profil ebenfalls deaktiviert. ',
        setInactiveText2: 'Set this card as inactive if you do not want it to be visible.',
        youDontHaveCards: 'Du hast noch keine existierenden Karten'
    },
    notification: {
        cardActivatedUserTitle: 'Karte erfolgreich aktiviert!',
        cardActivatedUserDesc: 'Ihre Karte wurde aktiviert und ist nun einsatzbereit. Bitte halten Sie sie sicher und geschützt.',
        cardActivatedOwnerTitle: 'Karte erfolgreich aktiviert!',
        cardActivatedOwnerDesc: 'Ihre Karte wurde aktiviert. Sie können sie jetzt zu Firmenkarten verschieben oder den Besitzer ändern.',
        cardAssignedTitle: 'Karte Ihrem Konto zugewiesen',
        cardAssignedDesc: 'Eine Karte wurde erfolgreich Ihrem Konto zugewiesen. Sie können sie jetzt über Ihre Kontoeinstellungen verwalten.',
        cardOwnerTransferredTitle: 'Karteninhaber übertragen',
        cardOwnerTransferredDesc: 'Das Eigentum Ihrer Karte wurde auf einen neuen Besitzer übertragen. Bitte kontaktieren Sie den Support, wenn Sie Fragen oder Bedenken haben.',
        cardOwnerReceivedTitle: 'Karteninhaber erhalten',
        cardOwnerReceivedDesc: 'Sie sind Karteninhaber geworden. Sie können sie jetzt über Ihre Kontoeinstellungen verwalten.',
        companyRoleAssignedTitle: 'Rolle im Unternehmen zugewiesen!',
        companyRoleAssignedDesc: 'Sie wurden als {{role}} des Unternehmens {{company}} ernannt.',
        noNewNotifications: 'Sie haben derzeit keine neuen Benachrichtigungen'
    },
    labels: {
        buttonBackgroundColor: 'Schaltfläche Hintergrundfarbe',
        buttonTextColor: 'Schaltfläche Textfarbe',
        cardBackgroundColor: 'Karten Hintergrundfarbe',
        cardTextColor: 'Schriftfarbe',
        city: 'Wohnort',
        company: 'Firma',
        companyBgColor: 'Firma Hintergrundfarbe',
        companyName: 'Name der Firma',
        companyTextColor: 'Firma Textfarbe',
        country: 'Land',
        cover: 'Deckblatt',
        coverImage: 'Deckbild',
        coverBackgroundColor: 'Deckblatt Hintergrundfarbe',
        createCompany: 'Unternehmen gründen',
        createNewCompany: 'Erstellen Sie ein neues Unternehmen',
        department: 'Abteilung',
        filterBy: 'Filtern nach',
        firstName: 'Vorname',
        fullname: 'Vollständiger Name',
        iconBackgroundColor: 'Symbol Hintergrundfarbe',
        iconsColor: 'Symbolfarbe',
        lastName: 'Nachname',
        pendingUserHasNotAcceptYet: 'Ausstehend: Warten darauf, dass der Benutzer das Konto aktiviert',
        position: 'Position',
        searchNameOrEmail: 'Suchen Sie nach Name oder E-Mail',
        streetAdress: 'Strasse',
        zipPostal: 'Postleitzahl'
    },
    messages: {
        companyInvitationRemoved: 'Die Unternehmenseinladung wurde erfolgreich entfernt.',
        companyMemberRemoved: 'Das Firmenmitglied wurde erfolgreich entfernt.',
        companyWasSuccessfullyCreated: 'Das Unternehmen wurde erfolgreich gegründet',
        contactSuccessfullySaved: 'Kontakt erfolgreich gespeichert',
        internalServerError: 'Internal server error',
        noIcardsFound: 'Keine iCards gefunden',
        noCompanyMemberFound: 'Keine Firmenmitglieder gefunden',
        noContactsFound: 'Keine Kontakte gefunden',
        passwordHasBeenChanged: 'Das Passwort wurde geändert.',
        passwordsDoNotMatch: 'Das Passwort und die Passwortbestätigung stimmen nicht überein.',
        profilesSuccessfullyUpdated: '{{noofCards}} Profile erfolgreich aktualisiert',
        profileWasSuccessfullyAssigned: 'Profil wurde erfolgreich zugewiesen.',
        unableToRemoveCompanyMember: 'Das Firmenmitglied kann nicht entfernt werden, da ihm Unternehmens-Kartenprofile zugewiesen sind.'
    },
    policy: {
        iHaveRead: 'Ich habe die gelesen und stimme ihnen zu',
    },
    profile: {
        colors: {
            custom: 'Individuell',
            default: 'Standart',
            green: 'Grün',
            purple: 'Violett',
            red: 'Rot',
            yellow: 'Gelb'
        },
        error: {
            fileTooLarge: 'Die Datei ist zu gross. Bitte lade eine Datei kleiner als 5MB hoch.',
            invalidEmailAddress: 'Ungültige E-Mail-Adresse',
            invalidFileType: 'Ungültiger Dateityp. Bitte lade eine Datei im PNG oder JPEG Format hoch.',
            specialCharacters: 'Ungültige Sonderzeichen'
        },
        general:{
            changeCover: 'Titelseite ändern',
            changePic: 'Bild ändern',
            dragAndDrop: 'reinziehen',
            noFileChosen: 'keine Datei ausgewählt',
            pngJpgGif : 'PNG, JPG, GIF bis zu 10MB',
            upload: 'Hochladen',
            uploadFile: 'Datei hochladen',
            uploadPic: 'Bild hochladen'
        },
        links: {
            addLink: 'Link hinzufügen'
        },
        contact: {
            addField: 'Feld hinzufügen'
        },
        noContentAdded: 'noch ohne Inhalt',
        poweredBy: 'Powered by :'
    },
    salesforce: {
        authenticationSuccess: 'Authentifizierung erfolgreich! Sie können nun die Integrationsfunktionen erkunden und Ihre Abläufe optimieren.',
        authenticationFailed: 'Authentifizierung fehlgeschlagen! Bitte überprüfen Sie Ihre Anmeldedaten.',
        consumerKey: 'Verbraucher-Schlüssel',
        consumerSecret: 'Verbraucher-Geheimnis',
        description: 'Cloudbasierte Software, die Unternehmen dabei hilft, mehr potenzielle Kunden zu finden und mehr Geschäfte abzuschliessen.',
        enableIntegration: 'Aktivieren Sie die Salesforce-Integration, um die Kontakte online aufzuschalten und auf dem neuesten Stand zu halten!',
        instanceUrl: 'Salesforce-Instanz-URL',
        loginToSalesforce: 'Bitte melden Sie sich bei Ihrem Salesforce-Konto an, erstellen Sie eine neue verbundene App und geben Sie die Rückruf-URL ein.',
        salesforce: 'Salesforce'
    },
    selector: {
        last7days: 'Letzte 7 Tage',
        last14days: 'Letzte 14 Tage',
        last30days: 'Letzte 30 Tage',
        totalViews: 'Total Ansichten'
    },
    settings: {
        deleteYourAccount: 'Löschen Sie Ihr Konto',
        permanentlyDeleteYourAccount: 'Löschen Sie Ihr Konto endgültig',
        privacyPolicy: 'Datenschutzrichtlinie',
        termsOfService: 'Nutzungsbedingungen',
        termsAndConditions: 'Geschäftsbedingungen',
        twofaEnableBtn: 'Aktivieren 2FA',
        twofaDisableBtn: 'Deaktivieren 2FA',
        twofaDesc: 'Die Zwei-Faktor-Authentifizierung schützt Ihr Konto, indem sie beim Anmelden einen zusätzlichen Sicherheitsschritt hinzufügt.',
        twofaTitle: 'Zwei-Faktor-Authentifizierung (2FA)'
    },
    activate: {
        assignProfile: 'Möchten Sie die iCard {{uuid}} zu diesem Konto hinzufügen?',
        failedToActivateProfile: 'Die Aktivierung Ihrer iCard ist fehlgeschlagen',
        profileActivation: 'Aktivieren Sie Ihre neue iCard',
        welcomeMessage1: 'Willkommen bei iCards',
        welcomeMessage2: 'Ihre iCard ist noch nicht aktiviert. Bitte klicken Sie auf die Schaltfläche unten, um es zu aktivieren',
        welcomeMessage3: 'Wenn Sie bereits ein Konto haben, müssen Sie sich anmelden, andernfalls müssen Sie sich registrieren',
        welcomeActivateButton: 'Aktivieren Sie Ihre iCard',
        yesActivate: 'Ja, aktivieren!',
        yourProfileIsActivated: 'Ihr iCard wurde erfolgreich aktiviert!'
    }
};