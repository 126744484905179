import {useMemo, useState} from 'react';
import {savePermissionsByCompanyId} from '../../api/permissionController';
import {COMPANY_ID} from '../../store/utils/userSession';
import {useAppDispatch, useAppSelector} from '../../store/appDispatch';
import {selectUser} from '../../store/slices/userSessionSlice';
import {CompanyPermission, CompanyPermissionUpdater} from '../../types/company';
import {ERROR} from '../../constants/common';
import i18n from '../../translations/i18n';
import {useAlert} from '../../context/AlertProvider';
import {PermissionConverter} from '../../converters/api/PermissionConverter';
import {selectCompanyPermission, updateCompanyPermission} from '../../store/slices/companySlice';

const usePermissionController = () => {
    const {setAlert} = useAlert();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState<boolean>(false);

    const user = useAppSelector(selectUser);
    const companyPermissions: CompanyPermission[] = useAppSelector(selectCompanyPermission);

    const companyId = useMemo(() => COMPANY_ID(), [user.activeCompany]);

    const savePermissionsByCompanyApi = (permission: CompanyPermissionUpdater) => {
        if (!companyId) return;

        setLoading(true);
        savePermissionsByCompanyId(companyId, permission)
            .then((res) => {
                if (res.error) {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                } else {
                    const permData = PermissionConverter.convertFromPermissionApiToCompanyPermission(res.data);
                    dispatch(updateCompanyPermission(permData));
                }
            })
            .finally(() => setLoading(false));
    };

    return {
        loading,
        companyPermissions,
        savePermissionsByCompanyApi
    };
};

export default usePermissionController;