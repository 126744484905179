import React, {FC, useState} from 'react';
import styles from './navigation-tabs.module.scss';

export interface Tab {
    key: number,
    value: string,
    handleAction: () => void
}

interface NavigationTabsProps {
    defaultTab: Tab;
    tabs: Tab[];
}

export const NavigationTabs: FC<NavigationTabsProps> = ({
    defaultTab, tabs
}) => {
    const [tabKey, setTabKey] = useState<number>(defaultTab?.key);

    const handleTabAction = (tab: Tab) => {
        setTabKey(tab.key);
        tab.handleAction();
    };

    return (
        <div className={styles.container}>
            {tabs.map(tab => {
                const isCurrentTab = tabKey === tab.key;

                return (
                    <div className={styles.tabWrapper} key={tab.key}>
                        {isCurrentTab &&
                            <div className={styles.redLine}></div>
                        }
                        <div
                            className={`${styles.tab} ${isCurrentTab ? styles.tabSelected : ''}`}
                            onClick={() => handleTabAction(tab)}
                        >
                            {tab.value}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};