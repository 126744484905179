import React, {FC, useEffect, useState} from 'react';
import {ThemeProvider} from '../../context/theme/ThemeProvider';
import {FormProvider} from '../../context/profile/FormProvider';
import {ToggleProvider} from '../../context/toggle/ToggleProvider';
import styles from '../profile/profile.module.scss';
import Preview from '../../components/profile/preview/Preview';
import General from '../../components/profile/general/General';
import Links from '../../components/profile/links/Links';
import Contact from '../../components/profile/contact/Contact';
import About from '../../components/profile/About/About';
import Appearance from '../../components/profile/appearance/Appearance';
import StickyFooter from '../../components/layout/stickyFooter/StickyFooter';
import {useCardProfileDataContext} from '../../context/profile/CardProfileDataProvider';
import {usePrompt} from '../../hooks/usePrompt';
import {CardProfileConverter} from '../../converters/api/CardProfileConverter';
import {CardProfileToggle} from '../../types/cardProfile';
import BulkProfileHeader from '../../components/layout/ProfileHeader/BulkProfileHeader';
import i18n from '../../translations/i18n';
import {saveToggleSection} from '../../api/cardProfile';
import {getBulkUrlDecodedToken} from '../../utils/jwt';
import useSaveCardBulkController from './useSaveCardBulkController';
import {ALL} from '../../types/userCardProfile';


const initialToggle: CardProfileToggle = {
    socialLinks: true,
    contact: true,
    about: true
};

const EditProfiles: FC = () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const selectAll = searchParams.get('selectAll') === 'true';
    const isMyCards = searchParams.get('isMyCards') === 'true';
    const noofCards = searchParams.get('len') ? Number(searchParams.get('len')) : 0;
    const searchText = searchParams.get('search');
    const searchStatus = searchParams.get('status');
    const cardProfileIds: number[] = getBulkUrlDecodedToken(token);

    const [profilePhoto, setProfilePhoto] = useState<string>('');
    const [coverPhoto, setCoverPhoto] = useState<string>('');
    const [isLargeScreen, setIsLargeScreen] = useState<boolean>(false);
    const [isSticky, setSticky] = useState<boolean>(false);

    const {
        newProfilePhoto, newCoverPhoto, isDirty, setIsDirty,
        aboutData, basic, setBasicData,
        links, setLinks, contactInfos,
        addressInfos, selectedColors, setSelectedColors,
        setNewProfilePhoto, setNewCoverPhoto, isCustom, setIsCustom
    } = useCardProfileDataContext();

    const { saveProfileInfo, cardProfileLoading } = useSaveCardBulkController(cardProfileIds, selectAll, isMyCards, noofCards);

    usePrompt({ isDirty });

    const handleSaveInfos = () => {
        const convertedLinks = CardProfileConverter.convertToSocialLinksUpdate(links);
        const convertedContact = CardProfileConverter.convertToContactUpdate(contactInfos);
        const covnertedAddress = CardProfileConverter.convertToAddressUpdate(addressInfos);
        const convertedContactAddress = CardProfileConverter.convertToContactSectionData(convertedContact, covnertedAddress);

        saveProfileInfo(
            aboutData,
            CardProfileConverter.convertToBasic(basic),
            newProfilePhoto as File,
            newCoverPhoto as File,
            convertedLinks,
            convertedContactAddress,
            CardProfileConverter.convertToAppearanceUpdate(selectedColors),
            searchText ?? undefined,
            searchStatus ?? undefined
        );

        setProfilePhoto( newProfilePhoto ? URL.createObjectURL(newProfilePhoto as Blob) : profilePhoto );
        setCoverPhoto( newCoverPhoto ? URL.createObjectURL(newCoverPhoto as Blob) : coverPhoto );

        setIsDirty(false);
    };

    const handleSaveToggle = (data: any) => saveToggleSection(cardProfileIds, data);

    const handleRemoveCover = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsDirty(true);
        setCoverPhoto('');
        setNewCoverPhoto(undefined);
    };

    const handleRemoveProfile = () => {
        setIsDirty(true);
        setProfilePhoto('');
        setNewProfilePhoto(undefined);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth <= 992);
        };
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const checkScrollTop = () => {
        setSticky(window.scrollY >= 70);
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, []);

    return (
        <ThemeProvider>
            <BulkProfileHeader
                isDirty={isDirty}
                saveInfos={handleSaveInfos}
                loading={cardProfileLoading}
                selectAll={selectAll}
                isMyCards={isMyCards}
                noofCards={noofCards}
                isSticky={isSticky}
                idsToken={token ?? ''}
                searchText={searchText ?? ''}
                searchStatus={searchStatus ?? ALL}
            />

            <FormProvider>
                <ToggleProvider toggle={initialToggle}>
                    <div className={styles.editingHeader}>
                        {i18n.t('header.youAreEditing', {noofCards: noofCards})}
                    </div>
                    <div className={`${styles.container} ${isSticky ? styles.sticky : ''}`}>
                        {!isLargeScreen &&
                            <div className={styles.leftContainer}>
                                <Preview
                                    profileBasic={basic}
                                    profileSocialLinks={links}
                                    profileContacts={contactInfos}
                                    profileAddress={addressInfos}
                                    profileAbout={aboutData}
                                    profileStyle={selectedColors}
                                    coverPhoto={coverPhoto}
                                    profilePhoto={profilePhoto}
                                    loading={false}
                                    cardProfile={undefined}
                                />
                            </div>
                        }
                        <div className={styles.rightContainer}>
                            <General
                                profileBasic={basic}
                                profilePhoto={profilePhoto}
                                coverPhoto={coverPhoto}
                                showGeneralHeader
                                showIcon
                                setNewProfilePhoto={setNewProfilePhoto}
                                setNewCoverPhoto={setNewCoverPhoto}
                                setIsDirty={setIsDirty}
                                setBasicData={setBasicData}
                                handleRemoveProfile={handleRemoveProfile}
                                handleDeleteCoverImage={handleRemoveCover}
                                newProfilePhoto={newProfilePhoto}
                                newCoverPhoto={newCoverPhoto}
                                bulkView={true}
                                isCompanyCard={!isMyCards}
                            />
                            <Links
                                saveToggleData={handleSaveToggle}
                                socialLinks={links}
                                setProfileSocialLinks={setLinks}
                                showIcon
                                bulkView={true}
                                isCompanyCard={!isMyCards}
                            />
                            <Contact
                                saveToggleData={handleSaveToggle}
                                contacts={contactInfos}
                                address={addressInfos}
                                showIcon
                                bulkView={true}
                                isCompanyCard={!isMyCards}
                            />
                            <About
                                saveToggleData={handleSaveToggle}
                                showIcon
                                isCompanyCard={!isMyCards}
                            />
                            <Appearance
                                showColor
                                showIcon
                                setIsDirty={setIsDirty}
                                profileStyle={selectedColors}
                                setProfileStyle={setSelectedColors}
                                setSelectedColors={setSelectedColors}
                                selectedColors={selectedColors}
                                isCustom={isCustom}
                                setIsCustom={setIsCustom}
                                isCompanyCard={!isMyCards}
                            />
                        </div>
                    </div>
                </ToggleProvider>
                <StickyFooter
                    isDirty={isDirty}
                    loading={cardProfileLoading}
                    saveInfos={handleSaveInfos}
                />
            </FormProvider>
        </ThemeProvider>
    );
};

export default EditProfiles;