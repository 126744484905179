import { WalletCard } from '../../types/walletCard';

export const WalletCardConverter = {
    convertFromWallteCardApiToWalletCard(data: any): WalletCard | undefined {
        if (!data) return undefined;

        return {
            id: data.wallet.id,
            cardProfileId: data.wallet.cardProfileId,
            walletBasic: {
                fullname: data.wallet.fullname,
                position: data.wallet.position,
                company: data.wallet.company,
                status: data.wallet.status,
            },
            walletStyle:  {
                cardBgColor: data.wallet.cardBgColor,
                cardTextColor: data.wallet.cardTextColor
            },
            uuid: data.uuid,
            profilePhotoPath: data.wallet.profilePhotoPath,
            coverPhotoPath: data.wallet.coverPhotoPath,
            companyCard: data.companyCard
        };
    }
};