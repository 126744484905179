import React, {FC, ReactElement, useState} from 'react';
import styles from './input.module.scss';
import { InputType, FILE } from '../../../types/input';
import i18n from '../../../translations/i18n';
import { inputValidation } from '../../../utils/validationUtils';

export interface InputMessage {
    error: boolean,
    msg: string
}

interface InputProps {
    id?: string;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    type?: InputType;
    placeholder?: string;
    className?: string;
    label?: string;
    notAllowedSpecialChars?: RegExp;
    disabled?: boolean;
    msg?: InputMessage;
    required?: boolean;
    max?: number;
    icon?: ReactElement;
    onIconPress?: () => void;
    name?: string;
}

export const Input: FC<InputProps> = ({
    id, label, value, type, placeholder, onChange, className,
    notAllowedSpecialChars, disabled, msg, required, max, icon, onIconPress, name
}) => {
    const [error, setError] = useState<string | undefined>(undefined);
    const maxLength = max ? max : 128;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (notAllowedSpecialChars && !inputValidation(value, notAllowedSpecialChars)) {
            setError(i18n.t('profile.error.specialCharacters'));
        } else {
            setError(undefined);
            if (onChange) {
                onChange(event);
            }
        }
    };

    const containerClassName = `${styles.container} ${className} ${type === FILE ? styles.hidden : ''}`;
    return (
        <div className={containerClassName}>
            <label className={styles.label}>
                {label} &nbsp; {required && <span>*</span>}
            </label>
            <div className={styles.inputWrapper}>
                <input
                    id={id}
                    name={name}
                    className={icon ? styles.inputWithIcon : styles.input}
                    onChange={handleChange}
                    value={value || ''}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    maxLength={maxLength}
                />
                {icon &&
                    <div
                        className={styles.icon}
                        onClick={onIconPress}
                    >
                        {icon}
                    </div>
                }
            </div>
            {(error || msg?.error) && <div className={styles.error}>{error || msg?.msg}</div>}
            {(msg && !msg.error) && <div className={styles.success}>{msg.msg}</div>}
        </div>
    );
};