import React, {FC} from 'react';
import styles from './custom-checkbox.module.scss';
import {Checkbox} from '@mui/material';
import { ReactComponent as CheckBoxIcon } from '../../../assets/icons/checkbox/checkbox.svg';
import { ReactComponent as SelectedCheckBoxIcon } from '../../../assets/icons/checkbox/selected-checkbox.svg';

interface CustomCheckboxProps {
    label: string;
    value: string;
    isChecked: boolean;
    onCheck: (value: string | string[]) => void;
    className?: string;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({
    label, value, isChecked, onCheck, className
}) => {
    return (
        <div
            key={value}
            className={`${styles.container} ${styles.text} ${className && className}`}
        >
            <Checkbox
                checked={isChecked}
                onChange={() => onCheck(value)}
                inputProps={{'aria-label': 'controlled'}}
                style={{padding: 0}}
                icon={<CheckBoxIcon />}
                checkedIcon={<SelectedCheckBoxIcon />}
            />
            <label className={styles.label}>
                {label}
            </label>
        </div>
    );
};

export default CustomCheckbox;