import React, {FC, useMemo} from 'react';
import styles from './permission-modal.module.scss';
import i18n from '../../translations/i18n';
import {COMPANY_ROLE_ADMIN, COMPANY_ROLE_OWNER, COMPANY_ROLE_USER} from '../../constants/accountRole';
import {CompanyRoleType} from '../../types/company';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import Divider from '../common/divider/Divider';

interface PermissionModalHeaderProps {
    role?: CompanyRoleType;
    onClose: () => void;
}

const PermissionModalHeader: FC<PermissionModalHeaderProps> = ({
    role, onClose
}) => {
    const roleName = useMemo(() => {
        if (role === COMPANY_ROLE_OWNER) return i18n.t('common.owners');
        if (role === COMPANY_ROLE_ADMIN) return i18n.t('common.admins');
        if (role === COMPANY_ROLE_USER) return i18n.t('common.users');
        return '';
    }, []);

    return (
        <div className={styles.headerContainer}>
            <div className={styles.header}>
                <div className={styles.textHeaderWrapper}>
                    <div className={styles.headerTxt}>
                        {i18n.t('common.permissions')}
                    </div>
                    <div className={styles.modalDesc}>
                        {i18n.t('modal.permissionModalText', {role: roleName})}
                    </div>
                </div>
                <div className={styles.icon} onClick={onClose}>
                    <CloseIcon stroke='#808080'/>
                </div>
            </div>
            <Divider/>
        </div>
    );
};

export default PermissionModalHeader;