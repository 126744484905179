import React, {FC, useState} from 'react';
import i18n from '../../../translations/i18n';
import SettingsBox from '../SettingsBox';
import styles from './integrations.module.scss';
import { ReactComponent as SalesforceIcon } from '../../../assets/icons/salesforce.svg';
import { ReactComponent as FileTextIcon } from '../../../assets/icons/file-text.svg';
import HeaderButton from '../../common/button/HeaderButton';
import SalesforceConnectForm from './salesforce/SalesforceConnectForm';
import {CircularProgress} from '@mui/material';
import CustomButton from '../../common/button/CustomButton';
import useIntegrationsController from './useIntegrationsController';
import {downloadFile} from '../../../utils/download';
import {salesforceInstructionsFilename, salesforceIntegrationInstructionsUrl} from '../../../api/base';

interface IntegrationsProps {
    salesforceFormActive: boolean;
}

const Integrations: FC<IntegrationsProps> = ({
    salesforceFormActive
}) => {
    const [openConnectForm, setOpenConnectForm] = useState<boolean>(salesforceFormActive);

    const { loading, isSalesforceEnabled } = useIntegrationsController();

    const handleDownloadInstructions = () => downloadFile(salesforceIntegrationInstructionsUrl, salesforceInstructionsFilename);

    if (loading) return <CircularProgress />;

    if (openConnectForm) {
        return (
            <SalesforceConnectForm
                isSalesforceEnabled={isSalesforceEnabled}
                onClose={() => setOpenConnectForm(false)}
            />
        );
    }

    return (
        <SettingsBox title={i18n.t('common.integrations')}>
            <div className={styles.box}>
                <div className={styles.salesforceBox}>
                    <div className={styles.salesforceIcon}>
                        <SalesforceIcon />
                    </div>
                    <div className={styles.textWrapper}>
                        <div className={`${styles.title} ${styles.text}`}>
                            {i18n.t('salesforce.salesforce')}
                        </div>
                        <div className={`${styles.desc} ${styles.text}`}>
                            {i18n.t('salesforce.description')}
                        </div>
                        {isSalesforceEnabled
                            ? <div className={styles.connected}>
                                {i18n.t('common.connected')}
                            </div>
                            : <div className={styles.downloadWrapper}>
                                <FileTextIcon/>
                                <div
                                    className={`${styles.downloadText} ${styles.text}`}
                                    onClick={handleDownloadInstructions}
                                >
                                    {i18n.t('common.downloadInstructions')}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.connectBtn}>
                    {isSalesforceEnabled
                        ? <CustomButton
                            onClick={() => setOpenConnectForm(true)}
                            text={i18n.t('common.view')}
                            sx={styles.btnView}
                        />
                        : <HeaderButton
                            title={i18n.t('common.connect')}
                            onClick={() => setOpenConnectForm(true)}
                            sx={styles.btn}
                        />
                    }
                </div>
            </div>

        </SettingsBox>
    );
};

export default Integrations;