import axiosInstance from './axiosInstance';
import {keycloakClient} from '../keycloak/Keycloak';

export const portalUrl = process.env.REACT_APP_PORTAL_URL;
export const salesforceInstructionsFilename = 'iCards-salesforce-integration.pdf';
export const salesforceIntegrationInstructionsUrl = `${portalUrl}/uploads/template/${salesforceInstructionsFilename}`;

export const configDefault = async () => {
    return {
        headers: {
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin',
        }
    };
};

export const configDefaultWithToken = async () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin'
        }
    };
};

export const configForImages = async () => {
    return {
        headers: {
            'Content-Type':'multipart/form-data',
            'X-XSS-Protection': '1; mode=block',
            'Access-Control-Allow-Origin': '*',
            credentials: 'same-origin',
        }
    };
};

export const getMultipleApiImageCall = async (urls: string[], useToken: boolean) => {
    const response: { data: any; error: any; status: number } = {
        data: {},
        error: null,
        status: 0,
    };

    const promises : any = urls.map(async (url) =>
        axiosInstance.get(url, {
            ...await (useToken ? configDefaultWithToken() : configDefault()),
            responseType: 'arraybuffer',
        })
    );

    await Promise.allSettled(promises)
        .then((results) => {
            const data: any = {};

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    const res = result.value;
                    data[urls[index]] = {
                        data: res.data,
                        headers: res.headers,
                    };
                } else {
                    console.log('Request failed:', result.reason);
                }
            });

            response.data = data;
            return data;
        })
        .catch((error) => {
            response.error = error.message;
            response.status = error.response?.status;
            return response;
        });

    return response;
};

export const getApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.get(url, config));
};

export const postApiCallWithoutBody = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.post(url, config));
};

export const postApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.post(url, data, config));
};

export const putApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.put(url, data, config));
};

export const patchApiCall = async (url: string, data: any, config: any) => {
    return getApiResponse(axiosInstance.patch(url, data, config));
};

export const deleteApiCall = async (url: string, config: any) => {
    return getApiResponse(axiosInstance.delete(url, config));
};

export const getApiResponse = async (request: Promise<any>) => {
    const response: { data: any; error: any; errorMsg: any, status: number } = {
        data: {},
        error: null,
        errorMsg: null,
        status: 0,
    };

    await request
        .then((res) => {
            response.data = res.data;
            response.status = res.status;
        })
        .catch((error) => {
            response.error = error;
            response.errorMsg = error.response?.data?.error;
            response.status = error.response?.status;
        });

    return response;
};

axiosInstance.interceptors.request.use(
    async (config) => {
        if (keycloakClient.authenticated) {
            try {
                await keycloakClient.updateToken(10); // refresh if less than 10 seconds remaining
                config.headers.Authorization = `Bearer ${keycloakClient.token}`;
            } catch (error) {
                console.error('Failed to refresh token', error);
                keycloakClient.login();
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);