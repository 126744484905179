import React, {FC, useRef, useState} from 'react';
import styles from './export-dropdown.module.scss';
import i18n from '../../../translations/i18n';
import { ReactComponent as ExportIcon } from '../../../assets/icons/export.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow/arrow-down.svg';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import {CircularProgress} from '@mui/material';

interface ExportDropdownProps {
    loading: boolean;
    onExport: () => void;
}

const ExportDropdown: FC<ExportDropdownProps> = ({
    loading, onExport
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const exportRef = useRef<HTMLDivElement>(null);

    useOutsideClick([menuRef, exportRef], () => { setOpen(false); });

    const handleOnExport = () => {
        onExport();
        setOpen(false);
    };

    if (loading) return <CircularProgress />;

    return (
        <div className={styles.container}>
            <div
                ref={menuRef}
                className={styles.export}
                onClick={() => setOpen(true)}
            >
                <ExportIcon />
                {i18n.t('common.export')}
                <ArrowDownIcon style={{marginLeft: '8px'}} />
            </div>
            { open &&
                <div
                    className={styles.modal}
                    onClick={handleOnExport}
                >
                    <div ref={exportRef} className={styles.textActive}>
                        Export as CSV
                    </div>
                </div>
            }
        </div>
    );
};

export default ExportDropdown;