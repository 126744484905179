import {CompanyPermission} from '../../types/company';
import {isEmpty} from '../../utils/common';
import {CompanyConverter} from './CompanyConverter';

export const PermissionConverter = {
    convertFromPermissionApiToCompanyPermissions(data: any[]): CompanyPermission[] {
        if (isEmpty(data)) return [];

        return data
            .map(d => PermissionConverter.convertFromPermissionApiToCompanyPermission(d))
            .filter((perm): perm is CompanyPermission => perm !== undefined);
    },
    convertFromPermissionApiToCompanyPermission(data: any): CompanyPermission | undefined {
        if (!data) return undefined;

        return {
            role: CompanyConverter.convertFromCompanyRoleApiToCompanyRole(data.role),
            permissions: data.permissionTypes
        };
    }
};