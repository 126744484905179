import {ChangeEvent, FC, RefObject, useMemo, useRef, useState} from 'react';
import i18n from '../../../translations/i18n';
import styles from './my-cards-menu.module.scss';
import { ReactComponent as EyePreviewIcon } from '../../../assets/icons/eye/eyePreview.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as AssignUserIcon } from '../../../assets/icons/assign-user.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as LockIcon } from '../../../assets/icons/lock.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg';
import { ReactComponent as CompanyIcon } from '../../../assets/icons/company.svg';
import { ReactComponent as RevertIcon } from '../../../assets/icons/revert.svg';
import {CustomSwitch} from '../../common/switch/CustomSwitch';
import {ACTIVE, CardProfileStatus, INACTIVE} from '../../../types/userCardProfile';
import Divider from '../../common/divider/Divider';
import {IS_COMPANY_ADMIN} from '../../../store/utils/userSession';
import {useOutsideClick} from '../../../hooks/useOutsideCLick';
import {useAppSelector} from '../../../store/appDispatch';
import {selectUser} from '../../../store/slices/userSessionSlice';

interface MyCardsMenuProps {
    handlePreview: () => void;
    handleEdit: () => void;
    handleAssignCardTo: () => void;
    handleChangeCardOwner: () => void;
    handleShare: () => void;
    handleSwitchStatus: (status: CardProfileStatus) => void;
    handleDelete: () => void;
    isActive: boolean;
    handleRemoveFromCompany: () => void;
    handleMoveToCompany: () => void;
    isMyCards?: boolean;
    onClose: () => void;
    canChangeOwner: boolean;
    canMoveToMyCards: boolean;
    menuIconRef: RefObject<HTMLDivElement>;
    sx?: any;
}

const MyCardsMenu: FC<MyCardsMenuProps> = ({
    handlePreview, handleEdit, handleAssignCardTo, handleChangeCardOwner, handleShare, handleSwitchStatus, handleDelete,
    isActive, handleRemoveFromCompany, handleMoveToCompany, isMyCards, onClose, canChangeOwner, canMoveToMyCards, menuIconRef, sx
}) => {
    const user = useAppSelector(selectUser);
    const [checkedSwitch, setCheckedSwitch] = useState<boolean>(!isActive);

    const menuRef = useRef<HTMLDivElement>(null);

    useOutsideClick([menuRef, menuIconRef], () => {
        onClose();
    });

    const isCompanyAdmin = useMemo(() => IS_COMPANY_ADMIN(), [user]);
    const canSeeMoveToCompanyCardsOption = isMyCards && isCompanyAdmin;

    const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        handleSwitchStatus(checked ? INACTIVE : ACTIVE);
        setCheckedSwitch(checked);
    };

    return (
        <div ref={menuRef} className={`${sx} ${styles.container}`}>
            <div className={`${styles.item} ${styles.pointer}`} onClick={handlePreview}>
                <div className={styles.iconWrapper}>
                    <EyePreviewIcon className={styles.icon}/>
                </div>
                <div className={styles.text}>
                    {i18n.t('common.previewCard')}
                </div>
            </div>
            <div className={`${styles.item} ${styles.pointer}`} onClick={handleEdit}>
                <div className={styles.iconWrapper}>
                    <EditIcon className={styles.icon}/>
                </div>
                <div className={styles.text}>
                    {i18n.t('common.editCard')}
                </div>
            </div>
            {isCompanyAdmin && !isMyCards &&
                <div className={`${styles.item} ${styles.pointer}`} onClick={handleAssignCardTo}>
                    <div className={styles.iconWrapper}>
                        <AssignUserIcon/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.assignCardTo')}
                    </div>
                </div>
            }
            {canChangeOwner &&
                <div className={`${styles.item} ${styles.pointer}`} onClick={handleChangeCardOwner}>
                    <div className={styles.iconWrapper}>
                        <AssignUserIcon/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.changeCardOwner')}
                    </div>
                </div>
            }
            <div className={`${styles.item} ${styles.pointer}`} onClick={handleShare}>
                <div className={styles.iconWrapper}>
                    <ShareIcon className={styles.icon}/>
                </div>
                <div className={styles.text}>
                    {i18n.t('common.shareCard')}
                </div>
            </div>
            {canSeeMoveToCompanyCardsOption &&
                <div className={`${styles.item} ${styles.pointer}`} onClick={handleMoveToCompany}>
                    <div className={styles.iconWrapper}>
                        <CompanyIcon className={styles.icon}/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.moveToCompany')}
                    </div>
                </div>
            }
            {canMoveToMyCards &&
                <div className={`${styles.item} ${styles.pointer}`} onClick={handleRemoveFromCompany}>
                    <div className={styles.iconWrapper}>
                        <RevertIcon className={styles.icon}/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.moveToMyCards')}
                    </div>
                </div>
            }
            <Divider/>
            <div className={`${styles.item} ${styles.inactiveWrapper}`}>
                <div className={styles.inactive}>
                    <div className={styles.iconWrapper}>
                        <LockIcon/>
                    </div>
                    <div className={styles.text}>
                        {i18n.t('common.setInactive')}
                    </div>
                    <div className={styles.switchWrapper}>
                        <CustomSwitch
                            checked={checkedSwitch}
                            handleChange={handleSwitchChange}
                        />
                    </div>
                </div>
                <div className={styles.subText}>
                    {i18n.t('myCards.setInactiveText')}
                </div>
            </div>
            <Divider/>
            <div className={`${styles.item} ${styles.pointer}`} onClick={handleDelete}>
                <div className={styles.iconWrapper}>
                    <TrashIcon/>
                </div>
                <div className={`${styles.text} ${styles.deleteText}`}>
                    {i18n.t('common.deletePermanently')}
                </div>
            </div>
        </div>
    );
};

export default MyCardsMenu;